import { createStyles } from "@mantine/core";
import { ThemeMode } from "../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  boxContainer: {
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[4]
        : theme.colors.light[2],
    height: "13rem",
    paddingLeft: 27,
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    paddingRight: 27,
    alignItems: "center",
    paddingBottom: "2rem",
  },
  boxContainerWithButton: {
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[4]
        : theme.colors.light[2],
    height: "13rem",
    paddingLeft: 27,
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    paddingRight: 27,
    alignItems: "center",
    paddingBottom: "4rem",
  },
  titleSection: {
    fontSize: 32,
    color: theme.colors.light[0],
  },
  descriptionSection: {
    fontSize: 16,
    color: theme.colors.light[0],
  },
  buttonText: {
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[0]
        : theme.colors.light[0],
    marginLeft: '0.5rem'
  },
  button: {
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[2]
        : "rgba(255, 255, 255, 0.4)",
    alignSelf: "center",
  },
  goBackButtonText: {
    color: theme.colors.dark[1],
    marginLeft: "1.5rem",
  },
  buttonGoBack: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "1rem",
    justifyContent: "space-between",
    border: "1.5px solid",
    borderRadius: 100,
    borderColor: theme.colors.dark[1],
    backgroundColor: "transparent",
    marginBottom: "0.5rem",
    "&: hover": {
      backgroundColor: "transparent",
    },
  },
  goBackIcon: {
    backgroundColor: theme.colors.dark[1],
    borderRadius: 50,
    position: "absolute",
    left: "0.3rem",
  },
}));

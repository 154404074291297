import { Box, Tooltip, useMantineTheme, Text } from "@mantine/core";
import { AlertCircle, CircleCheck, UserCircle } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { Link } from "react-router-dom";

const PendientRequest = ({
  tooltipValue,
  quantity,
  type,
  itemId,
  emptyPendientMessage,
}: {
  tooltipValue: string;
  quantity: number;
  type: string;
  itemId: string;
  emptyPendientMessage: string;
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const theme = useMantineTheme();

  return (
    <Link to={`/cliente/${itemId}?${tooltipValue}`} className={classes.link}>
      <Tooltip
        label={quantity > 0 ? t(`${tooltipValue}`) : t(`${emptyPendientMessage}`)}
      >
        <Box
          className={classes.requestBoxContainer}
          style={{
            backgroundColor:
              theme.colorScheme !== "dark"
                ? "transparent"
                : quantity > 0
                ? "#FFDB5C"
                : "#27A536",
            borderColor: quantity > 0 ? "#FFDB5C" : "#27A536",
          }}
        >
          {quantity > 0 ? (
            <>
              {type === "services" ? (
                <AlertCircle
                  color={
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[0]
                      : "#FFDB5C"
                  }
                />
              ) : (
                <UserCircle
                  color={
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[0]
                      : "#FFDB5C"
                  }
                />
              )}
            </>
          ) : (
            <CircleCheck
              color={
                theme.colorScheme === "dark" ? theme.colors.dark[0] : "#27A536"
              }
            />
          )}
          <Text className={classes.quantityText}>{quantity}</Text>
        </Box>
      </Tooltip>
    </Link>
  );
};

export default PendientRequest;

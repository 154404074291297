import { ICompany } from "../../../Types/company";
import apiUser from "../../../Api/users";
import apiClients from "../../../Api/clients";
import { IResponse } from "../../../Types/response";
const {
  getAll,
  create,
  getOne,
  update,
  updateStatus,
  resetPasswordDefault,
  resetPassword,
} = apiUser;
const getAllClients = apiClients.getAll;

export const getUsersData = async (
  page: number,
  limit: number,
  activeOrNot: boolean,
  keyword?: string
) => {
  const response: IResponse = { data: null, error: null, totalPages: 0 };
  try {
    const { data } = await getAll(page, limit, activeOrNot, keyword);
    const dataMapped = data?.items?.map((item: any) => ({
      id: item.id,
      fullname:
        `${item?.firstName} ${
          item?.lastName !== undefined ? item?.lastName : ""
        }` ?? "-",
      company: item?.client?.name ?? "-",
      email: item?.email ?? "-",
      phone: item?.phone ?? "-",
      rol:
        item?.role === "default-roles-regusa"
          ? "-"
          : item?.role === "authorized_user"
          ? "usuario"
          : item?.role,
      toDeleteDate: item?.toDeleteDate ?? "",
      isActivated: item?.isActivated,
      enabled: item?.enabled,
    }));
    response.data = dataMapped.sort((
      (a: any, b: any) => a.company - b.company)
    )
    response.totalPages = data?.meta?.totalPages;
  } catch (_) {
    response.error = false;
    response.totalPages = 0;
  }
  return response;
};

export const getClientsData = async () => {
  const response: IResponse = { data: null, error: null };
  try {
    const { data } = await getAllClients();
    response.data = data.map((item: ICompany) => ({
      label: item.name,
      value: item.id,
    }));
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const createUser = async (userData: any) => {
  const response: any = { data: null, err: null };
  try {
    const objToSubmit = {
      enabled: true,
      clientId: userData?.company,
      lastName: userData?.lastName,
      firstName: userData?.firstName,
      role: userData?.rol,
      email: userData?.email,
      phone: userData?.phone,
      position: userData?.position,
    };
    const { data } = await create(objToSubmit);
    response.data = data;
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const getUserById = async (userId: string | undefined) => {
  const response: IResponse = { data: null, error: null };
  try {
    const { data } = await getOne(userId);
    response.data = {
      firstName: data?.firstName ?? "",
      lastName: data?.lastName ?? "",
      email: data?.email ?? "",
      phone: data?.phone ?? "",
      position: data?.position ?? "",
      rol: data?.role ?? "",
      company: data?.client?.name ?? "",
      companyId: data?.client?.id ?? "",
      enabled: data?.enabled ?? false,
      toDeleteDate: data?.toDeleteDate ?? "",
    };
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const updateUser = async (userId: string, clientData: any) => {
  const response: IResponse = { data: null, error: null };
  try {
    const objToSubmit = {
      lastName: clientData.lastName,
      firstName: clientData.firstName,
      email: clientData.email,
      phone: clientData?.phone,
      position: clientData?.position,
      role: clientData?.rol,
      clientId: clientData?.company,
    };
    response.data = await update(userId, objToSubmit);
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const updateUserStatus = async (userId: string, status: boolean) => {
  const response: IResponse = { data: null, error: null };
  try {
    const { data } = await updateStatus(userId, status);
    response.data = data;
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const updateUserPassword = async (
  option: string,
  userId: string,
  password?: string
) => {
  const response: IResponse = { data: null, error: null };
  try {
    if (option === "generate") {
      const { data } = await resetPasswordDefault(userId);
      response.data = data;
    } else {
      const { data } = await resetPassword(userId, password);
      response.data = data;
    }
  } catch (_) {
    response.error = false;
  }
  return response;
};

import { createStyles } from "@mantine/core";
import { CustomFonts } from "../../../../Components/Fonts";
import { ThemeMode } from "../../../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  boxBreadcrumsContainer: {
    width: "100vw",
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[4]
        : theme.colors.light[0],
  },
  outletContainer: {
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[0]
        : theme.colors.light[0],
    position: "absolute",
    marginLeft: "1rem",
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 5,
    paddingLeft: 24,
    paddingRight: 24,
    marginTop: "-2%",
  },
  loader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
  },
  titleText: {
    ...CustomFonts.SubTitle(),
    fontWeight: "bold",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
  },
  divider: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
}));

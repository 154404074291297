import { Text, Tooltip } from "@mantine/core";
import { Services } from "../../../../Enums/services";
import { useStyles } from "./styles";

const ServicesItem = ({ services }: any) => {
  const { classes } = useStyles();

  return (
    <>
      {services?.map((item: any, index: number) => {
        return (
          index <= 2 && (
            <Text className={classes.itemText} key={index}>
              {item?.service?.name === Services.otherServices
                ? item?.service?.serviceType
                : item?.service?.name}
            </Text>
          )
        );
      })}
      {services?.length > 3 && (
        <Tooltip
          multiline
          label={
            <>
              {services.slice(3).map((item: any, index: number) => (
                <Text key={`${item?.service?.serviceType}_${index}`}>
                  {item?.service?.name === Services.otherServices
                    ? item?.service?.serviceType
                    : item?.service?.name}
                </Text>
              ))}
            </>
          }
        >
          <Text className={classes.serviceTypeText}>
            +{services.length - 3}
          </Text>
        </Tooltip>
      )}
    </>
  );
};

export default ServicesItem;

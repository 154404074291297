import { Container, Image } from "@mantine/core";
import LogoFull from "../../../Assets/Images/Svg/LogoFull.svg";
import NotAuthorizedImage from "../../../Assets/Images/Svg/NotAuthorized.svg";
import { useStyles } from "./styles";

const NotAuthorized = () => {
  const { classes } = useStyles();

  return (
    <Container className={classes.container}>
      <Image src={LogoFull} width={400} />
      <Image src={NotAuthorizedImage} width={500} />
    </Container>
  );
};

export default NotAuthorized;

import { Box } from "@mantine/core";
import { useStyles } from "./styles";

const OutletContainer = ({ children }: any) => {
  const { classes } = useStyles();

  return <Box className={classes.outletContainer}>{children}</Box>;
};

export default OutletContainer;

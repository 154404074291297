import { createStyles } from "@mantine/core";
import { CustomFonts } from "../../../../Components/Fonts";
import { ThemeMode } from "../../../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  cardContainer: {
    justifyContent: "space-between",
    border: "0.3px solid",
    borderColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[3]
        : theme.colors.light[3],
    marginTop: "1rem",
    alignItems: "center",
    display: "flex",
    borderRadius: 4,
    padding: '1rem',
    [theme.fn.smallerThan("sm")]: {
      display: "grid",
    },
  },
  textLabel: {
    ...CustomFonts.Small(),
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
    fontWeight: "bold",
  },
  rowValueText: {
    ...CustomFonts.Small(),
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
    fontWeight: "normal",
  },
  buttonAndTextContainer: {
    display: "flex",
    alignItems: "center",
  },
  switchText: {
    marginRight: "1rem",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
  },
  buttonText: {
    ...CustomFonts.Small(),
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[2]
        : theme.colors.light[2],
    fontWeight: "bold",
  },
  button: {
    marginLeft: "1rem",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  serviceList: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "-0.5rem",
    marginTop: "0.5rem",
  },
  itemText: {
    ...CustomFonts.Small(),
    fontWeight: "normal",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[0]
        : theme.colors.dark[1],
    backgroundColor: theme.colors.dark[2],
    borderRadius: 5,
    marginLeft: "0.5rem",
    alignSelf: "center",
    padding: "0px 2px",
  },
  serviceTypeText: {
    display: "inline",
    backgroundColor: theme.colors.dark[2],
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[0]
        : theme.colors.dark[1],
    padding: "0px 2px",
    borderRadius: 5,
    marginLeft: "0.5rem",
  },
}));

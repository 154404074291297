import { createStyles } from "@mantine/core";
import { CustomFonts } from "../../../../Components/Fonts";
import { ThemeMode } from "../../../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  mainContainer: {
    maxWidth: "none",
    display: "flex",
    flexDirection: "column",
  },
  userInfoTitle: {
    ...CustomFonts.BodyBold(),
    fontWeight: "bold",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
  },
  passwordContainer: {
    display: "flex",
    alignItems: "center",
  },
  passwordText: {
    marginLeft: "0.5rem",
    ...CustomFonts.Small(),
    fontWeight: "initial",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.gray[5]
        : theme.colors.light[1],
  },
  notification: {
    position: 'absolute',
    right: 0,
    top: '4rem'
  },
}));

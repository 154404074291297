import { createStyles } from "@mantine/core";
import { ThemeMode } from "../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  logoImage: {
    justifyContent: "center",
    display: "flex",
    marginLeft: "0.5rem",
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
  logoImageResponsive: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    paddingLeft: "3rem",
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === ThemeMode.DARK ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan("sm")]: {
      height: 42,
      display: "flex",
      alignItems: "center",
      width: "100%",
      backgroundColor:
        theme.colorScheme === ThemeMode.DARK
          ? theme.colors.dark[0]
          : theme.colors.light[0],
    },
    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === ThemeMode.DARK
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },
  subLink: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    borderRadius: theme.radius.md,
    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === ThemeMode.DARK
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
    }),
    "&:active": theme.activeStyles,
  },
  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    margin: -theme.spacing.md,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md}px ${theme.spacing.md * 2}px`,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[5]
        : theme.colors.gray[1]
    }`,
  },
  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
  mobileHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "0.5rem",
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
  boxUserInfoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  userNameContainer: {
    marginLeft: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  mainContainer: {
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[0]
        : theme.colors.light[0],
    // position: "sticky",
    // top: 0,
    // zIndex: 9999,
  },
  goBackButton: {
    backgroundColor: theme.colors.green,
    borderRadius: 50,
    "&:hover": {
      backgroundColor: theme.colors.lime,
    },
  },
  boxMenu: {
    position: "absolute",
    zIndex: 9,
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[0]
        : theme.colors.light[0],
    right: 0,
    top: "3.5rem",
    width: "12%",
  },
  menuItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    width: "100%",
  },
  itemIcon: {
    marginRight: "1rem",
  },
  itemText: {
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
  },
  usernameText: {
    marginBottom: "-0.5rem",
  },
  sidebarMenu: {
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[0]
        : theme.colors.light[0],
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
  buttonLogout: {
    backgroundColor: "transparent",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

import { Box, Button, Text, useMantineTheme } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Logout, Settings } from "tabler-icons-react";
import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import { ThemeMode } from "../../Enums/themeMode";

const Menu = ({ setToggleMenu, setIsLogoutModalVisible }: any) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const theme = useMantineTheme();

  return (
    <Box className={classes.boxMenu} onMouseLeave={() => setToggleMenu(false)}>
      <Button
        variant={"subtle"}
        className={classes.menuItem}
        onClick={() => navigate("/configuracion")}
      >
        <Settings
          color={
            theme.colorScheme === ThemeMode.DARK
              ? theme.colors.gray[1]
              : theme.colors.gray[7]
          }
          className={classes.itemIcon}
        />
        <Text className={classes.itemText}>{t("settings")}</Text>
      </Button>
      <Button
        onClick={() => setIsLogoutModalVisible(true)}
        className={classes.menuItem}
        variant={"subtle"}
      >
        <Logout
          color={
            theme.colorScheme === ThemeMode.DARK
              ? theme.colors.gray[1]
              : theme.colors.gray[7]
          }
          className={classes.itemIcon}
        />
        <Text className={classes.itemText}>{t("logout")}</Text>
      </Button>
    </Box>
  );
};

export default Menu;

import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

instance.interceptors.request.use(config => {
  const storage = localStorage.getItem("KC_TOKEN");
  let token = null;
  if (storage !== null) {
    token = JSON.parse(storage)?.token;
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = '';
  }
  return config;
}, error => Promise.reject(error));

export default instance;

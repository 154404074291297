import { Loader } from "@mantine/core";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";

const Dashboard = () => {
  return (
    <>
      <Suspense fallback={<Loader color="green" />}>
        <Outlet />
      </Suspense>
    </>
  );
};

export default Dashboard;

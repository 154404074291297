import {
  Box,
  Button,
  LoadingOverlay,
  Modal,
  MultiSelect,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  addServiceSelectedToClient,
  deleteServiceSelected,
} from "../../Controllers/servicesController";
import { Services } from "../../../../Enums/services";
import { ThemeMode } from "../../../../Enums/themeMode";

const AddServiceModal = ({
  isModalVisible,
  onCloseModal,
  data,
  setIsNotificationVisible,
  servicesDefaultValues,
  getClientInformation,
}: any) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { clientId } = useParams();
  const [formData, setFormData] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useMantineTheme();

  const getDefaultItems = () => {
    const values = servicesDefaultValues?.map((item: any) => {
      return item?.service?.name === Services.otherServices
        ? item?.service?.serviceType
        : item?.service?.name;
    });
    setFormData(values);
  };
  useEffect(() => {
    setIsLoading(true);
    if (servicesDefaultValues?.length > 0) getDefaultItems();
    setIsLoading(false);
  }, []);

  const onSubmit = async () => {
    setIsSubmitting(true);
    const response = await addServiceSelectedToClient(
      clientId,
      data,
      formData,
      servicesDefaultValues
    );
    const deleteServiceResponse = await deleteServiceSelected(
      formData,
      servicesDefaultValues
    );
    if (response || deleteServiceResponse) {
      setFormData([]);
      setIsSubmitting(false);
    }
    setIsNotificationVisible({
      isVisible: true,
      success: response || deleteServiceResponse ? true : false,
    });
    getClientInformation();
    onCloseModal();
  };

  return (
    <Modal
      opened={isModalVisible}
      onClose={onCloseModal}
      centered
      className={classes.modal}
    >
      <Box className={classes.topContentContainer}>
        <Text className={classes.modalTitle}>{t("addServiceTitle")}</Text>
        <Text>{t("servicesAvailable")}</Text>
      </Box>
      <>
        <MultiSelect
          color="green"
          data={data}
          multiple
          disabled={isSubmitting}
          defaultValue={formData}
          value={formData}
          onChange={(e) => setFormData(e)}
          maxDropdownHeight={150}
        />
        <Box className={classes.bottomContentContainer}>
          <Button
            disabled={isSubmitting}
            className={classes.cancelButton}
            variant="outline"
            onClick={onCloseModal}
          >
            {t("cancelOption")}
          </Button>
          <Button
            disabled={isSubmitting}
            className={classes.submitButton}
            variant="filled"
            onClick={onSubmit}
          >
            {!isSubmitting ? t("add") : t("loading")}
          </Button>
        </Box>
      </>
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ color: "green" }}
        overlayColor={
          theme.colorScheme === ThemeMode.DARK
            ? theme.colors.dark[0]
            : theme.colors.dark[1]
        }
      />
    </Modal>
  );
};

export default AddServiceModal;

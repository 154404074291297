import { Box, Button, Image, Modal, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import Logout from "../../../Assets/Images/Svg/Logout.svg";

type ILogOutModal = {
  isModalVisible: boolean;
  onCloseModal: () => void;
  title: string;
  description?: string;
  buttonCancelText: string;
  buttonCancelFunction: () => void;
  buttonConfirmText: string;
  buttonConfirmFunction: () => void;
};

const LogOutModal = (props: ILogOutModal) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const {
    isModalVisible,
    onCloseModal,
    title,
    description,
    buttonCancelText,
    buttonCancelFunction,
    buttonConfirmFunction,
    buttonConfirmText,
  } = props;

  return (
    <Modal
      opened={isModalVisible}
      onClose={() => onCloseModal()}
      centered
      className={classes.modal}
    >
      <Box className={classes.mainContainer}>
        <Box className={classes.boxIconContainer}>
          <Image src={Logout} />
        </Box>
        <Text className={classes.title}>{t(`${title}`)}</Text>
        {description && (
          <Text className={classes.description}>{t(`${description}`)}</Text>
        )}
      </Box>
      <Box className={classes.buttonsContainer}>
        <Button
          onClick={buttonCancelFunction}
          variant="outline"
          color={"green"}
          className={classes.button}
        >
          <Text>{t(`${buttonCancelText}`)}</Text>
        </Button>
        <Button
          onClick={buttonConfirmFunction}
          variant="filled"
          color={"green"}
          className={classes.button}
        >
          <Text>{t(`${buttonConfirmText}`)}</Text>
        </Button>
      </Box>
    </Modal>
  );
};

export default LogOutModal;

import { Container, Image } from "@mantine/core";
import LogoFull from "../../../Assets/Images/Svg/LogoFull.svg";
import NotFoundImage from "../../../Assets/Images/Svg/NotFound.svg";
import { useStyles } from "./styles";

const NotFound = () => {
  const { classes } = useStyles();

  return (
    <Container className={classes.container}>
      <Image src={LogoFull} width={400} />
      <Image src={NotFoundImage} width={500} />
    </Container>
  );
};

export default NotFound;

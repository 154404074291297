import apiServices from "../../../Api/services";
import { Services } from "../../../Enums/services";
import { IResponse } from "../../../Types/response";
const { getOne, create, update, deleteOneService, getAllServicesPaginated } =
  apiServices;

const validateStringify = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (_) {
    return false;
  }
};

export const getServicesData = async (
  page: number,
  limit: number,
  keyword?: string
) => {
  const response: IResponse = { data: null, error: null, totalPages: 0 };
  try {
    const { data } = await getAllServicesPaginated(page, limit, keyword);
    response.data = data?.items?.map((item: any) => {
      const wasteConverted = JSON.parse(item?.residueType);
      let serviceTypeConverted;
      if (validateStringify(item?.serviceType)) {
        serviceTypeConverted = validateStringify(item?.serviceType);
      } else {
        serviceTypeConverted = item.serviceType === "-" ? "" : item.serviceType;
      }
      return {
        id: item.id,
        serviceName: item.name ?? "",
        serviceType: serviceTypeConverted,
        wasteType: wasteConverted[0] === "" ? [] : wasteConverted,
        enableToEdit: item.enableToEdit,
      };
    });
    response.totalPages = data?.meta?.totalPages;
  } catch (_) {
    response.error = false;
    response.totalPages = 0;
  }
  return response;
};

export const getServiceById = async (id: string | undefined) => {
  const response: IResponse = { data: null, error: null };
  try {
    const { data } = await getOne(id);
    response.data = {
      id: data.id,
      serviceType: data.serviceType,
      wasteList: JSON.parse(data.residueType),
      pierNumber: data.dockNumber,
      containerNumber: data.containerNumber,
    };
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const createServiceForm = async (values: any) => {
  const response: IResponse = { data: null, error: null };
  try {
    const objToSubmit = {
      ...values,
      residueType: JSON.stringify(values.residueType),
      enableToEdit: true,
      name: Services.otherServices,
    };
    response.data = await create(objToSubmit);
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const updateService = async (values: any, id: string | undefined) => {
  const response: IResponse = { data: null, error: null };
  try {
    const objToSubmit = {
      ...values,
      residueType: JSON.stringify(values.residueType),
    };
    response.data = await update(id, objToSubmit);
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const deleteService = async (id: string | undefined) => {
  const response: IResponse = { data: null, error: null };
  try {
    const { data } = await deleteOneService(id);
    response.data = data.statusText === "Done";
  } catch (_) {
    response.error = false;
  }
  return response;
};

import { createStyles } from "@mantine/core";
import { CustomFonts } from "../../../../Components/Fonts";
import { ThemeMode } from "../../../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  mainContainer: {
    maxWidth: "none",
    display: "flex",
    flexDirection: "column",
  },
  createClientTitle: {
    ...CustomFonts.SubTitle(),
    fontWeight: "bold",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
  },
  loader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
}));

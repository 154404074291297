import apiClients from "../../../Api/clients";
import { IResponse } from "../../../Types/response";
const { getByIdByUserId } = apiClients;

export const getClientDataByUserId = async (userId: string) => {
  const response: IResponse = {
    data: null,
    error: null,
  };
  try {
    const { data } = await getByIdByUserId(userId);
    response.data = data;
  } catch (_) {
    response.error = false;
  }
  return response;
};

import { createStyles } from "@mantine/core";
import { CustomFonts } from "../../../../Components/Fonts";
import { ThemeMode } from "../../../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  mainContainer: {
    maxWidth: "none",
    display: "flex",
    flexDirection: "column",
  },
  boxTableContainer: {
    marginTop: "2rem",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowX: "scroll",
    position: "relative",
    flexWrap: "wrap",
  },
  input: {
    width: "15rem",
    float: "right",
    alignSelf: "self-end",
  },
  boxBreadcrumsContainer: {
    width: "100vw",
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[4]
        : theme.colors.light[0],
  },
  outletContainer: {
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[0]
        : theme.colors.light[0],
    position: "absolute",
    marginLeft: "1rem",
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 5,
    paddingLeft: 24,
    paddingRight: 24,
    height: "60vh",
    marginTop: "-2%",
  },
  loader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
  },
  userInfoTitle: {
    ...CustomFonts.BodyBold(),
    fontWeight: "bold",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
  },
  boxItemsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    [theme.fn.smallerThan('sm')]: {
      display: "grid",
    }
  },
  buttonAdd: {
    backgroundColor: theme.colors.dark[2],
    width: 130,
    marginRight: "2rem",
    ":hover": {
      backgroundColor: theme.colors.dark[2],
    },
  },
  buttonCancel: {
    borderColor: theme.colors.dark[2],
    color: theme.colors.dark[2],
    width: 130,
    marginRight: "2rem",
    ":hover": {
      borderColor: theme.colors.dark[2],
    },
  },
  buttonsContainer: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  passwordContainer: {
    display: "flex",
    alignItems: "center",
  },
  passwordText: {
    marginLeft: "0.5rem",
    ...CustomFonts.Small(),
    fontWeight: "initial",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
  },
  boxSelectContainer: {
    right: "1.5%",
  },
  switchUserContainer: {
    display: "flex",
    alignItems: "center",
  },
  userStatusText: {
    marginRight: 10,
    ...CustomFonts.BodyNormal(),
    fontWeight: "normal",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
  },
  boxTitleAndSwitchContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footerButtons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  resetPassword: {
    borderColor: theme.colors.dark[2],
    color: theme.colors.dark[2],
    width: "auto",
    float: "right",
    marginTop: "1rem",
    ":hover": {
      borderColor: theme.colors.dark[2],
    },
  },
  deleteRowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "50%",
  },
  deleteWarningText: {
    ...CustomFonts.Small(),
    fontWeight: "normal",
    lineHeight: "17px",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[3]
        : theme.colors.gray[7],
    marginLeft: "1rem",
  },
  buttonDelete: {
    backgroundColor: theme.colors.red[9],
    color: theme.colors.light[0],
    width: "auto",
    ":hover": {
      backgroundColor: theme.colors.red[9],
    },
  },
  buttonRestore: {
    borderColor: theme.colors.green,
    color: theme.colors.green,
    width: "auto",
    ":hover": {
      borderColor: theme.colors.green,
    },
  },
}));

import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  LoadingOverlay,
  MultiSelect,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useStyles } from "./styles";
import { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import CustomModal from "../../../../Components/CustomModals/Modal/Modal";
import { useNavigate, useParams } from "react-router-dom";
import {
  createServiceForm,
  deleteService,
  getServiceById,
  updateService,
} from "../../Controller/services";
import { ThemeMode } from "../../../../Enums/themeMode";

type FormType = {
  wasteEnabled: boolean;
  pierNumberValue: boolean;
  containerNumberValue: boolean;
  wasteList: string[];
};

const CreateServiceForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [formData, setFormData] = useState<FormType>({
    wasteEnabled: false,
    pierNumberValue: false,
    containerNumberValue: false,
    wasteList: [],
  });
  const [modal, setModal] = useState({
    isVisible: false,
    message: "",
    success: false,
  });

  const form = useForm({
    initialValues: {
      serviceType: "",
    },
    validate: {
      serviceType: (value) => (value ? null : "Campo requerido"),
    },
  });

  const getData = async () => {
    setIsLoading(true);
    const { data, error } = await getServiceById(id);
    if (error === null) {
      form.setValues({
        serviceType: data.serviceType,
      });
      setFormData((prev) => ({
        ...prev,
        wasteEnabled: data.wasteList.length > 0,
        pierNumberValue: data.pierNumber,
        containerNumberValue: data.containerNumber,
        wasteList: data.wasteList,
      }));
      setOptions(data.wasteList);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []);

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);
    const objToSubmit = {
      ...values,
      residueType: formData.wasteList,
      dockNumber: formData.pierNumberValue,
      containerNumber: formData.containerNumberValue,
      isActive: true,
    };
    if (!id) {
      onCreateService(objToSubmit);
    } else {
      onUpdateService(objToSubmit);
    }
  };

  const onCreateService = async (objToSubmit: any) => {
    const { data, error } = await createServiceForm(objToSubmit);
    setModal({
      isVisible: true,
      message: error === null ? "serviceCreated" : "serviceCreatedError",
      success: error === null,
    });
    if (error === null && !id) {
      form.reset();
      setFormData((prev) => ({
        ...prev,
        wasteEnabled: false,
        pierNumberValue: false,
        containerNumberValue: false,
        wasteList: [],
      }));
    }
    setIsSubmitting(false);
  };

  const onUpdateService = async (objToSubmit: any) => {
    const { data, error } = await updateService(objToSubmit, id);
    setModal({
      isVisible: true,
      message: error === null ? "serviceUpdated" : "serviceUpdatedError",
      success: error === null,
    });
    setIsSubmitting(false);
  };

  const onDeleteService = async () => {
    setIsSubmitting(true);
    const { data, error } = await deleteService(id);
    setIsDeleteModalVisible(false);
    setModal({
      isVisible: true,
      message: error === null ? "serviceDeleted" : "serviceDeletedError",
      success: error === null,
    });
  };

  const onCloseModal = () => {
    setModal((prev) => ({
      ...prev,
      isVisible: false,
    }));
    navigate(-1);
  };

  return (
    <>
      <Box className={classes.boxMainContainer}>
        <Box className={classes.inputContainer}>
          <TextInput
            disabled
            placeholder={t("otherServices") || ""}
            label={t("serviceName") || ""}
            size="sm"
            styles={(theme) => ({
              label: {
                color:
                  theme.colorScheme === ThemeMode.DARK
                    ? theme.colors.dark[1]
                    : theme.colors.light[1],
              },
              input: {
                border: `0.5px solid ${theme.colors.dark[4]}`,
                backgroundColor:
                  theme.colorScheme === ThemeMode.DARK
                    ? "transparent"
                    : theme.colors.light[0],
                marginRight: "2rem",
                "&:focus-within": {
                  borderColor: theme.colors.green[7],
                },
                color: "#000!important",
              },
            })}
          />
        </Box>
        <form onSubmit={form.onSubmit((values: any) => onSubmit(values))}>
          <TextInput
            required
            label={`${t("servicesType")}`}
            description={t("AddFieldOptions") || ""}
            disabled={isSubmitting}
            size="sm"
            {...form.getInputProps("serviceType")}
            inputWrapperOrder={["label", "input", "description", "error"]}
            styles={(theme) => ({
              label: {
                color:
                  theme.colorScheme === ThemeMode.DARK
                    ? theme.colors.dark[1]
                    : theme.colors.light[1],
              },
              input: {
                border: isSubmitting
                  ? `0.5px solid ${theme.colors.dark[4]}`
                  : theme.colorScheme === ThemeMode.DARK
                  ? `1px solid ${theme.colors.dark[3]}`
                  : "0.5px solid",
                backgroundColor:
                  theme.colorScheme === ThemeMode.DARK
                    ? "transparent"
                    : theme.colors.light[0],
                marginRight: "2rem",
                "&:focus-within": {
                  borderColor: theme.colors.green[7],
                },
                color: isSubmitting
                  ? "#000!important"
                  : theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
              },
            })}
          />
          <Divider className={classes.divider} />
          <>
            <Box className={classes.rowContainer}>
              <Box className={classes.textsContainer}>
                <Text className={classes.label}>{t("typeOfWaste")}</Text>
                <Text className={classes.text}>
                  {t("typeOfWasteDescription")}
                </Text>
              </Box>
              <Checkbox
                color={"green"}
                checked={formData.wasteEnabled}
                disabled={isSubmitting}
                onChange={() => {
                  setFormData((prev) => ({
                    ...prev,
                    wasteEnabled: !formData.wasteEnabled,
                  }));
                }}
              />
            </Box>
            {formData.wasteEnabled && (
              <Box className={classes.inputWasteContainer}>
                <MultiSelect
                  data={options}
                  searchable
                  creatable
                  disabled={isSubmitting}
                  defaultValue={formData.wasteList}
                  getCreateLabel={(query) => `+ ${t("add")} ${query}`}
                  onCreate={(query) => {
                    const item = { value: query, label: query };
                    setOptions((current) => [...current, item]);
                    return item;
                  }}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      wasteList: e,
                    }))
                  }
                  maxDropdownHeight={150}
                />
              </Box>
            )}
          </>
          <Divider className={classes.divider} />
          <>
            <Box className={classes.rowContainer}>
              <Box className={classes.textsContainer}>
                <Text className={classes.label}>{t("pierNumber")}</Text>
                <Text className={classes.text}>
                  {t("typeOfWasteDescription")}
                </Text>
              </Box>
              <Checkbox
                color={"green"}
                checked={formData.pierNumberValue}
                onChange={() =>
                  setFormData((prev) => ({
                    ...prev,
                    pierNumberValue: !formData.pierNumberValue,
                  }))
                }
                disabled={isSubmitting}
              />
            </Box>
          </>
          <Divider className={classes.divider} />
          <>
            <Box className={classes.rowContainer}>
              <Box className={classes.textsContainer}>
                <Text className={classes.label}>{t("containerNumber")}</Text>
                <Text className={classes.text}>
                  {t("typeOfWasteDescription")}
                </Text>
              </Box>
              <Checkbox
                color={"green"}
                checked={formData.containerNumberValue}
                disabled={isSubmitting}
                onChange={() =>
                  setFormData((prev) => ({
                    ...prev,
                    containerNumberValue: !formData.containerNumberValue,
                  }))
                }
              />
            </Box>
          </>
          <Box className={classes.buttonsFooter}>
            <Box className={classes.buttonsContainer}>
              <Button
                type="submit"
                className={classes.buttonAdd}
                disabled={isSubmitting}
              >
                <Text>
                  {isSubmitting ? t("loading") : id ? t("save") : t("add")}
                </Text>
              </Button>
              <Button
                variant={"outline"}
                className={classes.buttonCancel}
                type="reset"
                disabled={isSubmitting}
                onClick={() => navigate(-1)}
              >
                <Text>{t("cancelOption")}</Text>
              </Button>
            </Box>
            {id && (
              <Button
                onClick={() => setIsDeleteModalVisible(true)}
                className={classes.buttonDelete}
                disabled={isSubmitting}
              >
                <Text>{t("delete")}</Text>
              </Button>
            )}
          </Box>
        </form>
        <CustomModal
          isModalVisible={modal.isVisible}
          onCloseModal={onCloseModal}
          title={modal.message}
          success={modal.success}
        />
        <CustomModal
          isModalVisible={isDeleteModalVisible}
          onCloseModal={() => setIsDeleteModalVisible(false)}
          description={"deleteClientDescription"}
          success={false}
          buttons
          title={"deleteServiceTitle"}
          buttonCancelText={"cancelOption"}
          buttonCancelFunction={() => setIsDeleteModalVisible(false)}
          buttonConfirmText={"deleteOption"}
          buttonConfirmFunction={onDeleteService}
          disabled={isSubmitting}
        />
      </Box>
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ color: "green" }}
        overlayColor={
          theme.colorScheme === ThemeMode.DARK
            ? theme.colors.dark[0]
            : theme.colors.dark[1]
        }
      />
    </>
  );
};

export default CreateServiceForm;

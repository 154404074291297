import { Table, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

type IService = {
  id: string;
  serviceName: string;
  requestedBy: string;
  requestedAt: string;
};
const HistoryTable = ({ elements }: { elements: IService[] }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Table highlightOnHover className={classes.table}>
        <thead className={classes.tableHeader}>
          <tr>
            <th>
              <Text className={classes.headText}>{t("serviceName")}</Text>
            </th>
            <th>
              <Text className={classes.headText}>{t("requestedBy")}</Text>
            </th>
            <th>
              <Text className={classes.headText}>{t("applicationDate")}</Text>
            </th>
          </tr>
        </thead>
        {elements.length > 0 ? (
          <tbody className={classes.bodyContainer}>
            {elements?.map((element) => (
              <tr key={element.id}>
                <td>
                  <Text className={classes.tableText}>
                    {element?.serviceName}
                  </Text>
                </td>
                <td>
                  <Text className={classes.tableText}>
                    {element?.requestedBy || "-"}
                  </Text>
                </td>
                <td>
                  <Text className={classes.tableText}>
                    {element?.requestedAt}
                  </Text>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody className={classes.bodyContainer}>
            <tr>
              <td>
                <Text className={classes.emptyResultsMessage}>
                  {t("emptyResults")}
                </Text>
              </td>
            </tr>
          </tbody>
        )}
      </Table>
    </>
  );
};

export default HistoryTable;

import { useKeycloak } from "@react-keycloak/web";
import { decodeJwt } from "jose";
import NotAuthorized from "../Modules/NotAuthorized/Views";
import ErrorForbidden from "../Modules/ErrorForbidden/Views";

export const RolePolicy = {
  MATCH_ANY: "MATCH_ANY",
  MATCH_ALL: "MATCH_ALL",
};

export const Roles = {
  ADMIN: "admin",
  SUPERADMIN: "superadmin",
};

export const Protected = ({
  roles,
  children,
  policy = RolePolicy.MATCH_ALL,
  route = false,
}: {
  roles: any[];
  children: any;
  policy?: string;
  route: boolean;
}) => {
  const {
    keycloak: { token, authenticated, login },
  } = useKeycloak();
  if (!authenticated || !token) login();

  const payload: any = token ? decodeJwt(token) : null;

  if (!roles || roles.length === 0) return children;

  if (!payload.realm_access?.roles) return route ? <ErrorForbidden /> : <></>;

  let hasPermission = false;
  if (policy === RolePolicy.MATCH_ANY)
    hasPermission = roles?.some((role) =>
      payload.realm_access.roles.includes(role)
    );
  if (policy === RolePolicy.MATCH_ALL)
    hasPermission = roles?.every((role) =>
      payload.realm_access.roles.includes(role)
    );

  if (hasPermission) return children;
  return route ? <NotAuthorized /> : <></>;
};

import {
  Box,
  Container,
  LoadingOverlay,
  Notification,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InfoCircle } from "tabler-icons-react";
import CustomHeaderBreadcrumbs from "../../../../Components/CustomHeaderBreadcrumbs";
import CustomModal from "../../../../Components/CustomModals/Modal/Modal";
import { useTranslation } from "react-i18next";
import { getClientDataById } from "../../Controllers/clientsController";
import { createUser } from "../../Controllers/usersController";
import { useStyles } from "./styles";
import OutletContainer from "../../../../Components/OutletContainer";
import CreateUserForm from "../../Components/CreateUserForm";
import { useViewportSize } from "@mantine/hooks";
import { ThemeMode } from "../../../../Enums/themeMode";

const CreateUser = () => {
  const { clientId }: any = useParams();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { width } = useViewportSize();
  const iconSize = width > 700 ? "1rem" : "3rem";
  const [clientData, setClientData] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isNotificationErrorVisible, setNotificationErrorVisible] =
    useState(false);
  const [isModalVisible, setIsModalVisible] = useState({
    isVisible: false,
    success: false,
  });

  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      position: "",
      rol: "",
    },
    validate: {
      rol: (value) => (value ? null : t("required_field")),
      email: (value) => {
        if (/^\S+@\S+$/.test(value)) return null;
        if (value.length === 0) return t("required_field");
        return t("invalid_email");
      },
    },
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const { data, error } = await getClientDataById(clientId);
    if (error !== null) {
      setNotificationErrorVisible(true);
    }
    setClientData(data);
  };

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);
    const { data, error } = await createUser(values, clientId);
    setIsModalVisible({
      isVisible: true,
      success: error === null,
    });
    setIsSubmitting(false);
    if (data !== null) {
      form.reset();
    }
  };

  const onCloseModal = () => {
    setIsModalVisible((prev) => ({
      ...prev,
      isVisible: false,
    }));
    navigate(-1);
  };

  return (
    <>
      <CustomHeaderBreadcrumbs
        title={
          clientData
            ? `${clientData?.name} - ${t("createUser")}`
            : `${t("createUser")}`
        }
        description={"userCrud"}
      />
      <OutletContainer>
        <Container className={classes.mainContainer}>
          <Text className={classes.userInfoTitle}>{t("userInfoTitle")}</Text>
          <LoadingOverlay
            visible={isSubmitting}
            loaderProps={{ color: "green" }}
            overlayColor={
              theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[0]
                : theme.colors.dark[1]
            }
          />
          <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <CreateUserForm isSubmitting={isSubmitting} form={form} />
          </form>
          <Box className={classes.passwordContainer}>
            <InfoCircle
              color={
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.gray[5]
                  : theme.colors.light[1]
              }
              size={iconSize}
            />
            <Text className={classes.passwordText}>
              {t("passwordGeneratedText")}
            </Text>
          </Box>
        </Container>
      </OutletContainer>
      {isModalVisible.isVisible && (
        <CustomModal
          isModalVisible={isModalVisible.isVisible}
          onCloseModal={() => {
            if (isModalVisible.success) {
              onCloseModal();
            } else {
              setIsModalVisible((prev) => ({
                ...prev,
                isVisible: false,
              }));
            }
          }}
          title={isModalVisible.success ? "userCreated" : "userCreatedError"}
          success={isModalVisible.success}
        />
      )}
      {isNotificationErrorVisible && (
        <Notification
          className={classes.notification}
          color="red"
          onClose={() => setNotificationErrorVisible(false)}
        >
          <Text>{t("generalError")}</Text>
        </Notification>
      )}
    </>
  );
};

export default CreateUser;

import { createStyles } from "@mantine/core";
import { CustomFonts } from "../../Fonts";

export const useStyles = createStyles((theme) => ({
  mainContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginBottom: "2rem",
  },
  boxIconContainer: {
    justifyContent: "center",
    display: "flex",
    border: "2px solid",
    width: "4rem",
    height: "4rem",
    alignItems: "center",
    borderRadius: 50,
    marginBottom: "1rem",
  },
  title: {
    ...CustomFonts.SubTitle(),
    fontWeight: "bold",
    textAlign: "center",
  },
  description: {
    ...CustomFonts.Small(),
    fontWeight: "normal",
    textAlign: "center",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "1rem",
    paddingLeft: "5rem",
    paddingRight: "5rem",
    marginBottom: "2rem",
  },
}));

import { ActionIcon, Box, Table, Text, useMantineTheme } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Pencil } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { ThemeMode } from "../../../../Enums/themeMode";
import { useGetRoleName } from "../../../../Hooks/useGetRoleName";

type IUsersListTable = {
  elements: any[];
  disabled?: boolean;
  clientId: string;
};

const UsersListTable = ({ elements, disabled, clientId }: IUsersListTable) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const role = useGetRoleName();

  return (
    <>
      <Table highlightOnHover className={classes.table}>
        <thead className={classes.tableHeader}>
          <tr>
            <th>
              <Text className={classes.headText}>{t("nameAndLastName")}</Text>
            </th>
            <th>
              <Text className={classes.headText}>{t("position")}</Text>
            </th>
            <th>
              <Text className={classes.headText}>{t("emailLabel")}</Text>
            </th>
            <th>
              <Text className={classes.headText}>{t("phoneLabel")}</Text>
            </th>
            <th>
              <Text
                className={classes.headText}
                style={{ textAlign: "center" }}
              >
                Rol
              </Text>
            </th>
            <th>
              <Text
                className={classes.headText}
                style={{ textAlign: "center" }}
              >
                {t("actionsLabel")}
              </Text>
            </th>
          </tr>
        </thead>
        {elements.length > 0 ? (
          <tbody className={classes.bodyContainer}>
            {elements.map((element: any) => (
              <tr
                key={element.id.toString()}
                style={{
                  backgroundColor: element?.enabled
                    ? "transparent"
                    : "#6D6D6D33",
                }}
              >
                <td>
                  <Text
                    style={{
                      color: !element?.enabled
                        ? "#6D6D6D33"
                        : theme.colorScheme === ThemeMode.DARK
                        ? theme.colors.gray[1]
                        : theme.colors.light[1],
                    }}
                  >
                    {element?.firstName || "-"} {element?.lastName}
                  </Text>
                </td>
                <td>
                  <Text
                    style={{
                      color: !element?.enabled
                        ? "#6D6D6D33"
                        : theme.colorScheme === ThemeMode.DARK
                        ? theme.colors.gray[1]
                        : theme.colors.light[1],
                    }}
                  >
                    {element?.position || "-"}
                  </Text>
                </td>
                <td>
                  <Text
                    style={{
                      color: !element?.enabled
                        ? "#6D6D6D33"
                        : theme.colorScheme === ThemeMode.DARK
                        ? theme.colors.gray[1]
                        : theme.colors.light[1],
                    }}
                  >
                    {element?.email || "-"}
                  </Text>
                </td>
                <td>
                  <Text
                    style={{
                      color: !element?.enabled
                        ? "#6D6D6D33"
                        : theme.colorScheme === ThemeMode.DARK
                        ? theme.colors.gray[1]
                        : theme.colors.light[1],
                    }}
                  >
                    {element?.phone || "-"}
                  </Text>
                </td>
                <td>
                  <Text
                    className={classes.roles}
                    style={{
                      backgroundColor:
                        element?.role.toString() === "admin"
                          ? theme.colors.green[4]
                          : element?.role.toString() === "superadmin"
                          ? theme.colors.yellow[4]
                          : theme.colors.red[4],
                    }}
                  >
                    {element?.role === "authorized_user"
                      ? t("authorizedUser")
                      : element?.role || "-"}
                  </Text>
                </td>
                <td>
                  <Box className={classes.actionsContainer}>
                    <ActionIcon
                      color={
                        theme.colorScheme === ThemeMode.DARK
                          ? theme.colors.dark[0]
                          : theme.colors.light[0]
                      }
                      disabled={disabled}
                      className={classes.iconActionContainer}
                      onClick={() => {
                        if(role === 'admin'){
                          navigate(`cliente/${clientId}/editar-usuario/${element.id}`)
                        }else{
                          navigate(`editar-usuario/${element.id}`)
                        }
                      }}
                    >
                      <Pencil size={15} color={"#FFF"} />
                    </ActionIcon>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody className={classes.bodyContainer}>
            <tr>
              <td>
                <Text className={classes.emptyResultsMessage}>
                  {t("emptyResults")}
                </Text>
              </td>
            </tr>
          </tbody>
        )}
      </Table>
    </>
  );
};

export default UsersListTable;

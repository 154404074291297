import { createStyles } from "@mantine/core";
import { ThemeMode } from "../../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  mainContainer: {
    maxWidth: "none",
    display: "flex",
    flexDirection: "column",
  },
  boxTableContainer: {
    marginTop: "1rem",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowX: "scroll",
    position: "relative",
    flexWrap: "wrap",
  },
  input: {
    width: "15rem",
    float: "right",
    alignSelf: "self-end",
  },
  loader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
  },
  tabText: {
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
  },
  notification: {
    position: 'absolute',
    right: 0,
    top: '4rem'
  }
}));

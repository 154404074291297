import {
  Box,
  LoadingOverlay,
  Notification,
  Pagination,
  Tabs,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Check, Search } from "tabler-icons-react";
import CustomHeaderBreadcrumbs from "../../../../Components/CustomHeaderBreadcrumbs";
import { useTranslation } from "react-i18next";
import CompanyDetailCard from "../../Components/CompanyDetailCard";
import HiredServices from "../../Components/HiredServices";
import HistoryTable from "../../Components/HistoryTable";
import PendientServicesTable from "../../Components/PendientServicesTable";
import UsersListTable from "../../Components/UsersListTable";
import UsersPendientListTable from "../../Components/UsersPendingListTable";
import {
  activateOrDesactivate,
  getClientDataByIdPaginated,
  getHistory,
  getPendingServicesList,
  getServices,
  getServicesByClientId,
  toggleServiceRequest,
  updateByStatus,
} from "../../Controllers/clientsController";
import { useStyles } from "./styles";
import Countdown from "../../../../Components/Countdown";
import OutletContainer from "../../../../Components/OutletContainer";
import AddServiceModal from "../../Components/AddServiceModal";
import { ThemeMode } from "../../../../Enums/themeMode";
import { Protected, Roles } from "../../../../Navigation/Protected";
import { useGetRoleName } from "../../../../Hooks/useGetRoleName";
import { useKeycloak } from "@react-keycloak/web";
import { decodeJwt } from "jose";
import { getClientDataByUserId } from "../../../Dashboard/controller/dashboardController";

const ClientDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { clientId }: any = useParams();
  const { search } = useLocation();
  const theme = useMantineTheme();
  const role = useGetRoleName();
  const [clientIdState, setClientStateId] = useState("");
  const searchSplitted = search.split("?")[1];
  const {
    keycloak: { token },
  }: any = useKeycloak();
  const tokenDecoded: any = decodeJwt(token);
  const [timer, setTimer] = useState<number>(0);
  const [actualPage, setActualPage] = useState<number>(1);
  const [historyTotalPages, setHistoryTotalPages] = useState<any>(0);
  const [clientData, setClientData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(searchSplitted || "usersList");
  const [pendingUsers, setPendingUsers] = useState<any>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isAddNewServiceVisible, setIsAddNewServiceVisible] = useState(false);
  const [clientservicesList, setClientServicesList] = useState<any>([]);
  const [generalServicesList, setGeneralServicesList] = useState<any>([]);
  const [servicesHistoryList, setServicesHistoryList] = useState<any[]>([]);
  const [pendingServices, setPendingServices] = useState<any[]>([]);
  const [activeUsers, setActiveUsers] = useState<any>([]);
  const [activeUsersTotalPages, setActiveUsersTotalPages] = useState<any>(0);
  const [pendingUsersTotalPages, setPedingUsersTotalPages] = useState<any>(0);
  const [pendingServicesTotalPages, setPendingServicesTotalPages] =
    useState<any>(0);
  const [isNotificationVisible, setIsNotificationVisible] = useState({
    isVisible: false,
    success: false,
  });
  const [isNotificationErrorVisible, setNotificationErrorVisible] =
    useState(false);

  useEffect(() => {
    getClientId();
  }, []);

  const getClientId = async () => {
    await getClientByUserInfo();
  };

  useEffect(() => {
    if (clientIdState) {
      getClientInformation();
    }
  }, [clientIdState]);

  const getClientInformation = async () => {
    setIsLoading(true);
    await getData(actualPage, "", activeTab);
    await getServicesById();
    await getServicesHistory(actualPage);
    await getPendingServices(actualPage);
    await getGeneralServices();
    setIsLoading(false);
  };

  const getClientByUserInfo = async () => {
    if (!clientId || clientId === undefined) {
      const { data, error } = await getClientDataByUserId(tokenDecoded.sub);
      if (error === null) {
        setClientStateId(data.id);
      }
    } else {
      setClientStateId(clientId);
    }
  };

  const getData = async (
    actualPage: number,
    wordForSearch?: string,
    tab?: string
  ) => {
    setIsLoading(true);
    setActualPage(actualPage);
    const isUserActiveTabEnabled =
      tab !== undefined ? tab === "usersList" : true;
    const { data, error, totalPages } = await getClientDataByIdPaginated(
      clientIdState,
      10,
      actualPage,
      isUserActiveTabEnabled,
      wordForSearch
    );
    if (error === null) {
      setPendingUsers(data.clientUsers.items);
      setActiveUsers(data.clientUsers.items);
      setClientData(data);
      if (totalPages) {
        setActiveUsersTotalPages(totalPages);
        setPedingUsersTotalPages(totalPages);
      }
    } else {
      setNotificationErrorVisible(true);
    }
    setIsLoading(false);
  };

  const getServicesById = async () => {
    const { data, error } = await getServicesByClientId(clientIdState);
    if (error !== null) {
      setNotificationErrorVisible(true);
    }
    setClientServicesList(data);
  };

  const getServicesHistory = async (
    actualPage: number,
    wordForSearch?: string
  ) => {
    setActualPage(actualPage);
    const { data, totalPages } = await getHistory(
      clientIdState,
      10,
      actualPage,
      wordForSearch
    );
    setServicesHistoryList(data);
    setHistoryTotalPages(totalPages);
  };

  const getPendingServices = async (
    actualPage: number,
    wordForSearch?: string
  ) => {
    const { data, totalPages } = await getPendingServicesList(
      clientIdState,
      10,
      actualPage,
      wordForSearch
    );
    setPendingServices(data);
    setPendingServicesTotalPages(totalPages);
  };

  const getGeneralServices = async () => {
    const { data, error } = await getServices();
    if (error !== null) {
      setNotificationErrorVisible(true);
    }
    setGeneralServicesList(data);
  };

  const toggleStatus = async (status: boolean) => {
    setIsUpdating(true);
    const { error } = await updateByStatus(clientIdState, status);
    if (error === null) {
      getClientInformation();
    }
    setIsUpdating(false);
    setIsNotificationVisible({
      isVisible: true,
      success: error === null,
    });
  };

  const onHandleSearch = (keyword: string) => {
    if (keyword === "") {
      getData(1);
      getServicesHistory(1);
      getPendingServices(1);
    } else {
      setActualPage(1);
      if (activeTab === "usersList") {
        getData(1, keyword);
        return;
      }
      if (activeTab === "history") {
        getServicesHistory(1, keyword);
        return;
      }
      if (activeTab === "servicesPendient") {
        getPendingServices(1, keyword);
        return;
      }
      if (activeTab === "usersPendient") {
        getData(1, keyword);
        return;
      }
    }
  };

  useEffect(() => {
    if (isNotificationVisible.isVisible) {
      setTimeout(() => {
        setIsNotificationVisible((prev) => ({
          ...prev,
          isVisible: false,
        }));
      }, 3000);
    }
  }, [isNotificationVisible]);

  const activateOrDesactivateUser = async (
    id: string,
    enableOrDisabled: boolean
  ) => {
    const { error } = await activateOrDesactivate(id, enableOrDisabled);
    if (error !== null) {
      setNotificationErrorVisible(true);
    }
    getClientInformation();
    setIsNotificationVisible({
      isVisible: true,
      success: error === null,
    });
  };

  const activateOrDesactivateService = async (
    id: string,
    enableOrDisabled: boolean
  ) => {
    const { error } = await toggleServiceRequest(id, enableOrDisabled);
    if (error !== null) {
      setNotificationErrorVisible(true);
    }
    getClientInformation();
    setIsNotificationVisible({
      isVisible: true,
      success: error === null,
    });
  };

  const inputChanged = (value: string) => {
    clearTimeout(timer);
    const newTimer: any = setTimeout(() => {
      onHandleSearch(value);
    }, 500);
    setTimer(newTimer);
  };

  return (
    <>
      {!isLoading && clientData?.toDeleteDate && (
        <Countdown type={"cliente"} date={clientData?.toDeleteDate} />
      )}
      <CustomHeaderBreadcrumbs
        title={clientData?.name || ""}
        description={"userCrud"}
        showButton={clientData?.toDeleteDate ? false : true}
        buttonTitle={"createUser"}
        buttonAction={() => navigate(`/cliente/${clientIdState}/crear-usuario`)}
      />
      <OutletContainer>
        <LoadingOverlay
          visible={isLoading}
          loaderProps={{ color: "green" }}
          overlayColor={
            theme.colorScheme === ThemeMode.DARK
              ? theme.colors.dark[0]
              : theme.colors.dark[1]
          }
        />
        <>
          <CompanyDetailCard clientData={clientData} />
          <HiredServices
            isActive={clientData?.isActive}
            onChangeCompanyStatus={toggleStatus}
            disabled={clientData?.toDeleteDate !== ""}
            isUpdating={isUpdating}
            setIsAddNewServiceVisible={setIsAddNewServiceVisible}
            services={clientservicesList}
          />
          <Box className={classes.boxSearchbarContainer}>
            <TextInput
              placeholder={t("searchPlaceholder") || ""}
              onChange={(e: any) => inputChanged(e.target.value)}
              icon={<Search size={15} />}
              size="sm"
              inputWrapperOrder={["label", "input", "description", "error"]}
              styles={(theme) => ({
                label: {
                  color:
                    theme.colorScheme === ThemeMode.DARK
                      ? theme.colors.dark[1]
                      : theme.colors.light[1],
                },
                input: {
                  border: `0.5px solid ${
                    theme.colorScheme === ThemeMode.DARK
                      ? theme.colors.dark[3]
                      : theme.colors.dark[4]
                  }`,
                  backgroundColor:
                    theme.colorScheme === ThemeMode.DARK
                      ? "transparent"
                      : theme.colors.light[0],
                  marginRight: "2rem",
                  "&:focus-within": {
                    borderColor: theme.colors.green[7],
                  },
                  color: theme.colorScheme === ThemeMode.DARK ? "#FFF" : "#000",
                },
              })}
            />
          </Box>
          <Tabs
            defaultValue={activeTab}
            color="green"
            className={classes.tabsContainer}
          >
            <Tabs.List>
              <Tabs.Tab
                value="usersList"
                onClick={() => {
                  setActiveTab("usersList");
                  getData(1, "", "usersList");
                }}
              >
                <Text className={classes.tabText}>
                  {role === "admin" ? t("userList") : t("activeUsers")}
                </Text>
              </Tabs.Tab>
              <Tabs.Tab value="history" onClick={() => setActiveTab("history")}>
                <Text className={classes.tabText}>
                  {role === "admin" ? t("historyServices") : t("historyTab")}
                </Text>
              </Tabs.Tab>
              <Protected roles={[Roles.SUPERADMIN]} route={false}>
                <Tabs.Tab
                  value="servicesPendient"
                  onClick={() => setActiveTab("servicesPendient")}
                >
                  <Text className={classes.tabText}>
                    {t("pendientServicesTab")}
                  </Text>
                </Tabs.Tab>
                <Tabs.Tab
                  value="usersPendient"
                  onClick={() => {
                    setActiveTab("usersPendient");
                    getData(1, "", "usersPendient");
                  }}
                >
                  <Text className={classes.tabText}>
                    {t("pendientUsersTab")}
                  </Text>
                </Tabs.Tab>
              </Protected>
            </Tabs.List>
            <Tabs.Panel value="usersList" pt="xs">
              <UsersListTable
                disabled={clientData?.toDeleteDate !== ""}
                elements={activeUsers || []}
                clientId={clientIdState}
              />
              {activeUsers?.length > 0 && (
                <Pagination
                  total={activeUsersTotalPages}
                  onChange={(e) => getData(e)}
                  color={"green"}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel value="history" pt="xs">
              <HistoryTable elements={servicesHistoryList || []} />
              {servicesHistoryList?.length > 0 && (
                <Pagination
                  total={historyTotalPages}
                  onChange={(e) => getServicesHistory(e)}
                  color={"green"}
                />
              )}
            </Tabs.Panel>
            <Protected roles={[Roles.SUPERADMIN]} route={false}>
              <Tabs.Panel value="servicesPendient" pt="xs">
                <PendientServicesTable
                  activateOrDesactivateService={activateOrDesactivateService}
                  elements={pendingServices || []}
                />
                {pendingServices?.length > 0 && (
                  <Pagination
                    total={pendingServicesTotalPages}
                    onChange={(e) => getServicesHistory(e)}
                    color={"green"}
                  />
                )}
              </Tabs.Panel>
              <Tabs.Panel value="usersPendient" pt="xs">
                <UsersPendientListTable
                  activateOrDesactivateUser={activateOrDesactivateUser}
                  elements={pendingUsers || []}
                />
                {pendingUsers?.length > 0 && (
                  <Pagination
                    total={pendingUsersTotalPages}
                    onChange={(e) => getData(e)}
                    color={"green"}
                  />
                )}
              </Tabs.Panel>
            </Protected>
          </Tabs>
        </>
      </OutletContainer>
      {isAddNewServiceVisible && (
        <AddServiceModal
          isModalVisible={isAddNewServiceVisible}
          onCloseModal={() => setIsAddNewServiceVisible(false)}
          data={generalServicesList}
          setIsNotificationVisible={setIsNotificationVisible}
          servicesDefaultValues={clientservicesList}
          getClientInformation={getClientInformation}
        />
      )}
      {isNotificationVisible.isVisible && (
        <Notification
          color={isNotificationVisible.success ? "green" : "red"}
          onClose={() =>
            setIsNotificationVisible((prev) => ({
              ...prev,
              isVisible: false,
            }))
          }
          icon={<Check />}
          title={
            isNotificationVisible.success
              ? t(`clientUpdatedTitle`)
              : t(`clientUpdatedError`)
          }
          className={classes.notification}
        />
      )}
      {isNotificationErrorVisible && (
        <Notification
          className={classes.notification}
          color="red"
          onClose={() => setNotificationErrorVisible(false)}
        >
          <Text>{t("generalError")}</Text>
        </Notification>
      )}
    </>
  );
};

export default ClientDetail;

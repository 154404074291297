import instance from ".";
import { RoutePrefix } from "../Constants";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll: function () {
    return instance.get(RoutePrefix.CLIENTS);
  },
  getAllPaginated: function (page: number, limit: number, clientEnabled: boolean, keyword?: string) {
    if (keyword) {
      return instance.get(
        `${RoutePrefix.CLIENTS}/paginate?limit=${limit}&page=${page}&search=${keyword}&active=${clientEnabled}`
      );
    } else {
      return instance.get(
        `${RoutePrefix.CLIENTS}/paginate?limit=${limit}&page=${page}&active=${clientEnabled}`
      );
    }
  },
  deleteById: function (id: string | undefined, obj: any) {
    return instance.delete(`${RoutePrefix.CLIENTS}/${id}`, {
      data: obj,
    });
  },
  create: function (obj: any) {
    return instance.post(RoutePrefix.CLIENTS, obj);
  },
  update: function (id: string, obj: any) {
    return instance.put(`${RoutePrefix.CLIENTS}/${id}`, obj);
  },
  getById: function (id: string) {
    return instance.get(`${RoutePrefix.CLIENTS}/${id}`);
  },
  getByIdPaginated: function (id: string, limit: number, page: number, isActive: boolean, keyword?: string) {
    if(keyword){
      return instance.get(`${RoutePrefix.CLIENTS}/${id}/paginate?limit=${limit}&page=${page}&search=${keyword}&active=${isActive}`);
    }else{
      return instance.get(`${RoutePrefix.CLIENTS}/${id}/paginate?limit=${limit}&page=${page}&search=&active=${isActive}`);
    }
  },
  toggleStatus: function (id: string, isActive: boolean) {
    return instance.put(`${RoutePrefix.CLIENTS}/${id}`, {
      isActive: isActive,
    });
  },
  getClientServices: function (id: string) {
    return instance.get(`${RoutePrefix.CLIENT_SERVICES}/${id}`);
  },
  getByIdByUserId: function (id: string) {
    return instance.get(`${RoutePrefix.CLIENTS}/user/${id}`);
  },
};

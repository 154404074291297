import instance from ".";
import { RoutePrefix } from "../Constants";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllServices: function () {
    return instance.get(RoutePrefix.SERVICES);
  },
  getAllServicesPaginated: function (
    page: number,
    limit: number,
    keyword?: string
  ) {
    return instance.get(
      `${RoutePrefix.SERVICES}/paginate?search=${keyword}&limit=${limit}&page=${page}`
    );
  },
  addService: function (clientId: string, serviceId: string | number) {
    return instance.post(RoutePrefix.CLIENT_SERVICES, {
      client: parseInt(clientId),
      service: serviceId,
    });
  },
  deleteOne: function (id: string) {
    return instance.delete(`${RoutePrefix.CLIENT_SERVICES}/${id}`);
  },
  getOne: function (id: string | undefined) {
    return instance.get(`${RoutePrefix.SERVICES}/${id}`);
  },
  create: function (obj: any) {
    return instance.post(RoutePrefix.SERVICES, obj);
  },
  update: function (id: string | undefined, obj: any) {
    return instance.put(`${RoutePrefix.SERVICES}/${id}`, obj);
  },
  deleteOneService: function (id: string | undefined) {
    return instance.delete(`${RoutePrefix.SERVICES}/${id}`);
  },
  getAllServicesHistory: function (
    id: string,
    limit: number,
    page: number,
    keyword?: string
  ) {
    if (keyword) {
      return instance.get(
        `${RoutePrefix.SERVICE_REQUEST}/${id}/paginate?limit=${limit}&page=${page}&search=${keyword}`
      );
    } else {
      return instance.get(
        `${RoutePrefix.SERVICE_REQUEST}/${id}/paginate?limit=${limit}&page=${page}`
      );
    }
  },
  getPendingServices: function (id: string) {
    return instance.get(`${RoutePrefix.CLIENT_SERVICES}/${id}`);
  },
  getPendingServicesPaginated: function (
    id: string,
    limit: number,
    page: number,
    keyword?: string
  ) {
    if (keyword) {
      return instance.get(
        `${RoutePrefix.CLIENT_SERVICES}/${id}/paginate?limit=${limit}&page=${page}&search=${keyword}`
      );
    } else {
      return instance.get(
        `${RoutePrefix.CLIENT_SERVICES}/${id}/paginate?limit=${limit}&page=${page}`
      );
    }
  },
  updateServiceActivation: function (id: string, isActive: boolean) {
    return instance.put(`${RoutePrefix.CLIENT_SERVICES}/${id}/activate`, {
      isActivated: isActive,
    });
  },
};

import { ActionIcon, Box, Table, Text, useMantineTheme } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Pencil } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { ThemeMode } from "../../../../Enums/themeMode";

type IUsers = {
  id: string;
  fullname: string;
  company: string;
  email: string;
  phone: string;
  rol: number;
};

const UsersTable = ({ elements }: { elements: IUsers[] }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useMantineTheme();

  return (
    <>
      <Table highlightOnHover className={classes.table}>
        <thead className={classes.tableHeader}>
          <tr>
            <th>
              <Text className={classes.headText}>{t("nameAndLastName")}</Text>
            </th>
            <th>
              <Text className={classes.headText}>{t("companyLabel")}</Text>
            </th>
            <th>
              <Text className={classes.headText}>{t("emailLabel")}</Text>
            </th>
            <th>
              <Text className={classes.headText}>{t("phoneLabel")}</Text>
            </th>
            <th style={{ textAlign: "center" }}>
              <Text className={classes.headText}>Rol</Text>
            </th>
            <th style={{ textAlign: "center" }}>
              <Text className={classes.headText}>{t("actionsLabel")}</Text>
            </th>
          </tr>
        </thead>
        {elements.length > 0 ? (
          <tbody className={classes.bodyContainer}>
            {elements.map((element: any) => (
              <tr
                key={element.id}
                style={{
                  backgroundColor: element?.enabled
                    ? "transparent"
                    : "#6D6D6D33",
                }}
              >
                <td>
                  <Text
                    style={{
                      color: !element?.enabled
                        ? "#6D6D6D33"
                        : theme.colorScheme === ThemeMode.DARK
                        ? theme.colors.gray[1]
                        : theme.colors.light[1],
                    }}
                  >
                    {element?.fullname}
                  </Text>
                </td>
                <td>
                  <Text
                    style={{
                      color: !element?.enabled
                        ? "#6D6D6D33"
                        : theme.colorScheme === ThemeMode.DARK
                        ? theme.colors.gray[1]
                        : theme.colors.light[1],
                    }}
                  >
                    {element?.company}
                  </Text>
                </td>
                <td>
                  <Text
                    style={{
                      color: !element?.enabled
                        ? "#6D6D6D33"
                        : theme.colorScheme === ThemeMode.DARK
                        ? theme.colors.gray[1]
                        : theme.colors.light[1],
                    }}
                  >
                    {element.email}
                  </Text>
                </td>
                <td>
                  <Text
                    style={{
                      color: !element?.enabled
                        ? "#6D6D6D33"
                        : theme.colorScheme === ThemeMode.DARK
                        ? theme.colors.gray[1]
                        : theme.colors.light[1],
                    }}
                  >
                    {element.phone}
                  </Text>
                </td>
                <td>
                  <Text
                    className={classes.roles}
                    style={{
                      backgroundColor:
                        element?.rol.toString() === "admin"
                          ? theme.colors.green[4]
                          : element?.rol.toString() === "superadmin"
                          ? theme.colors.yellow[4]
                          : theme.colors.red[4],
                    }}
                  >
                    {element?.rol}
                  </Text>
                </td>
                <td>
                  <Box className={classes.actionsContainer}>
                    <ActionIcon
                      className={classes.iconActionContainer}
                      onClick={() =>
                        navigate(`/usuario/editar-usuario/${element.id}`)
                      }
                    >
                      <Pencil
                        size={15}
                        color={
                          theme.colorScheme === ThemeMode.DARK
                            ? theme.colors.dark[0]
                            : theme.colors.light[0]
                        }
                      />
                    </ActionIcon>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody className={classes.bodyContainer}>
            <tr>
              <td>
                <Text className={classes.emptyResultsMessage}>
                  {t("emptyResults")}
                </Text>
              </td>
            </tr>
          </tbody>
        )}
      </Table>
    </>
  );
};

export default UsersTable;

import CustomHeader from "../CustomHeader";
import { Box, useMantineTheme } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import Sidebar from "../Sidebar";
import { ThemeMode } from "../../Enums/themeMode";

const MainLayout = ({ children }: any) => {
  const { width } = useViewportSize();
  const theme = useMantineTheme();

  return (
    <>
      <CustomHeader />
      <Box
        style={{
          flexDirection: "row",
          display: "flex",
          backgroundColor:
            theme.colorScheme === ThemeMode.DARK ? "#1C2939" : "#dee2e6",
        }}
      >
        {width > 800 && <Sidebar />}
        <Box style={{ width: "100%", overflowY: "scroll", height: "90vh" }}>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;

import {
  Box,
  Container,
  LoadingOverlay,
  Notification,
  Pagination,
  Tabs,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Search } from "tabler-icons-react";
import CustomHeaderBreadcrumbs from "../../../Components/CustomHeaderBreadcrumbs";
import UsersTable from "../Components/UsersTable";
import { getUsersData } from "../Controller/userController";
import { useStyles } from "./styles";
import OutletContainer from "../../../Components/OutletContainer";
import { useTranslation } from "react-i18next";
import { ThemeMode } from "../../../Enums/themeMode";

const Users = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const [timer, setTimer] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState<any>([]);
  const [isNotificationErrorVisible, setNotificationErrorVisible] =
    useState(false);
  const [activeTab, setActiveTab] = useState("activeUsers");
  const [actualPage, setActualPage] = useState(1);
  const [pages, setPages] = useState<any>(0);

  const getUsersList = async (
    actualPage: number,
    tab?: string,
    keyword?: string
  ) => {
    setIsLoading(true);
    setActualPage(actualPage);
    const areActiveClients = tab === "activeUsers";
    const { data, error, totalPages } = await getUsersData(
      actualPage,
      10,
      areActiveClients,
      keyword
    );
    if (error !== null) {
      setNotificationErrorVisible(true);
    }
    setPages(totalPages);
    setUserList(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getUsersList(actualPage, activeTab);
  }, []);

  const inputChanged = (value: string) => {
    clearTimeout(timer);
    const newTimer: any = setTimeout(() => {
      getUsersList(1, activeTab, value);
    }, 500);
    setTimer(newTimer);
  };

  return (
    <>
      <CustomHeaderBreadcrumbs
        title={"users"}
        description={"usersAdm"}
        showButton
        buttonTitle={"createUsers"}
        buttonAction={() => navigate("/usuario/crear-usuario")}
      />
      <OutletContainer>
        <Container className={classes.mainContainer}>
          <Box className={classes.input}>
            <TextInput
              placeholder={t("searchPlaceholder") || ""}
              icon={<Search size={"1rem"} />}
              onChange={(e: any) => inputChanged(e.target.value)}
              size="sm"
              inputWrapperOrder={["label", "input", "description", "error"]}
              styles={(theme) => ({
                label: {
                  color:
                    theme.colorScheme === ThemeMode.DARK
                      ? theme.colors.dark[1]
                      : theme.colors.light[1],
                },
                input: {
                  border: `0.5px solid ${theme.colors.dark[4]}`,
                  backgroundColor:
                    theme.colorScheme === ThemeMode.DARK
                      ? "transparent"
                      : theme.colors.light[0],
                  marginRight: "2rem",
                  "&:focus-within": {
                    borderColor: theme.colors.green[7],
                  },
                  color: "#000!important",
                },
              })}
            />
          </Box>
          <Box className={classes.boxTableContainer}>
            <LoadingOverlay
              visible={isLoading}
              loaderProps={{ color: "green" }}
              overlayColor={
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[0]
                  : theme.colors.dark[1]
              }
            />
            <>
              <Tabs defaultValue="activeUsers" color={"green"}>
                <Tabs.List>
                  <Tabs.Tab
                    value="activeUsers"
                    onClick={() => {
                      setActiveTab("activeUsers");
                      getUsersList(1, "activeUsers");
                    }}
                  >
                    <Text className={classes.tabText}>{t("activeUsers")}</Text>
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="deletedUsers"
                    onClick={() => {
                      setActiveTab("deletedUsers");
                      getUsersList(1, "deletedUsers");
                    }}
                  >
                    <Text className={classes.tabText}>{t("deletedUsers")}</Text>
                  </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value={"activeUsers"}>
                  <UsersTable elements={userList || []} />
                  {userList?.length > 0 && (
                    <Pagination
                      onChange={(e: any) => getUsersList(e, 'activeUsers')}
                      total={pages}
                      color="green"
                    />
                  )}
                </Tabs.Panel>
                <Tabs.Panel value={"deletedUsers"}>
                  <UsersTable elements={userList || []} />
                  {userList?.length > 0 && (
                    <Pagination
                      onChange={(e: any) => getUsersList(e, 'deletedUsers')}
                      total={pages}
                      color="green"
                    />
                  )}
                </Tabs.Panel>
              </Tabs>
            </>
          </Box>
        </Container>
      </OutletContainer>
      {isNotificationErrorVisible && (
        <Notification
          className={classes.notification}
          color="red"
          onClose={() => setNotificationErrorVisible(false)}
        >
          <Text>{t("generalError")}</Text>
        </Notification>
      )}
    </>
  );
};

export default Users;

import { Box, Card, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

const CompanyDetailCard = ({ clientData }: any) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.cardContainer}>
      <Box>
        <Text className={classes.textLabel}>{t("companyLabel")}</Text>
        <Text className={classes.rowValueText}>{clientData?.name}</Text>
      </Box>
      <Box>
        <Text className={classes.textLabel}>{t("street")}</Text>
        <Text className={classes.rowValueText}>
          {clientData?.street || "-"}
        </Text>
      </Box>
      <Box>
        <Text className={classes.textLabel}>{t("phoneLabel")}</Text>
        <Text className={classes.rowValueText}>{clientData?.phone || "-"}</Text>
      </Box>
      <Box>
        <Text className={classes.textLabel}>CIF</Text>
        <Text className={classes.rowValueText}>{clientData?.cif || "-"}</Text>
      </Box>
    </Card>
  );
};

export default CompanyDetailCard;

import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: 'hidden',
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    marginLeft: '-0.5rem',
    marginTop: '-0.5rem'
  },
  loader: {
    margin: 0,
  },
}));

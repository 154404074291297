import {
  Box,
  Button,
  Checkbox,
  Modal,
  PasswordInput,
  Text,
} from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

type IRestorePasswordModal = {
  isVisible: boolean;
  onCloseModal: () => void;
  username: string;
  onSubmit: (
    option: string,
    password?: string,
    userChangeThePassword?: boolean
  ) => Promise<void>;
};

const RestorePasswordModal = ({
  isVisible,
  onCloseModal,
  username = "",
  onSubmit,
}: IRestorePasswordModal) => {
  const [checkboxSelected, setCheckboxSelected] = useState("generate");
  const [password, setPassword] = useState("");
  const [userChangePasswordSelected, setUserChangePasswordSelected] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { classes } = useStyles();
  const { t } = useTranslation();

  const onChangePassword = async () => {
    setIsLoading(true);
    await onSubmit(checkboxSelected, password, userChangePasswordSelected);
    setIsLoading(false);
  };

  const disableButtonSubmit = () => {
    if (checkboxSelected === "") return true;
    if (checkboxSelected === "generate") return false;
    if (
      checkboxSelected === "create" &&
      (password !== "" || userChangePasswordSelected)
    )
      return false;
    if (isLoading) {
      return false;
    }
    return true;
  };

  const closeModal = () => {
    setCheckboxSelected("generate");
    setPassword("");
    setUserChangePasswordSelected(false);
    onCloseModal();
  };

  return (
    <Modal
      opened={isVisible}
      onClose={() => closeModal()}
      className={classes.modalContainer}
      centered
    >
      <Box>
        <Text className={classes.title}>{t("resetPasswordTitle")}</Text>
        <Text className={classes.userName}>{username}</Text>
      </Box>
      <Box className={classes.generatePasswordContainer}>
        <Checkbox
          color={"green"}
          label={t("generatePasswordAutomatically")}
          checked={checkboxSelected === "generate"}
          onClick={() => {
            if (checkboxSelected === "generate") {
              setCheckboxSelected("");
            } else {
              setCheckboxSelected("generate");
            }
          }}
        />
        {checkboxSelected === "generate" && (
          <Text className={classes.generatePasswordText}>
            {t("generatePasswordAutomaticallyText")}
          </Text>
        )}
      </Box>
      <Box>
        <Checkbox
          color={"green"}
          label={t("createPasswordLabel")}
          checked={checkboxSelected === "create"}
          onClick={() => {
            if (checkboxSelected === "create") {
              setCheckboxSelected("");
            } else {
              setCheckboxSelected("create");
            }
          }}
        />
        {checkboxSelected === "create" && (
          <Box className={classes.passwordInputContainer}>
            <PasswordInput
              label={t("password")}
              onChange={(e) => setPassword(e?.target?.value)}
              value={password}
              color="green"
              className={classes.passwordInput}
            />
          </Box>
        )}
      </Box>
      <Box className={classes.buttonContainer}>
        <Button
          variant="outline"
          className={classes.buttonCancel}
          onClick={() => closeModal()}
        >
          <Text>{t("cancelOption")}</Text>
        </Button>
        <Button
          variant="filled"
          className={classes.buttonSubmit}
          disabled={disableButtonSubmit()}
          onClick={() => onChangePassword()}
        >
          <Text>{t("restart")}</Text>
        </Button>
      </Box>
    </Modal>
  );
};

export default RestorePasswordModal;

import {
  Box,
  Button,
  Text,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { Moon, Sun } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { ThemeMode } from "../../Enums/themeMode";

const SwitchTheme = () => {
  const { classes } = useStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === ThemeMode.DARK;
  const theme = useMantineTheme();
  const {t} = useTranslation();

  return (
    <Button
      className={classes.mainContainer}
      variant="subtle"
      onClick={() => {
        localStorage.setItem(
          "color-scheme",
          colorScheme === ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK
        );
        toggleColorScheme();
      }}
    >
      <Text className={classes.modeText}>
        {dark ? t("lightMode") : t("darkMode")}
      </Text>
      <Box className={classes.icon}>
        {dark ? (
          <Sun color={theme.colors.dark[0]} size={20} />
        ) : (
          <Moon color={theme.colors.light[0]} size={20} />
        )}
      </Box>
    </Button>
  );
};

export default SwitchTheme;

import apiServices from "../../../Api/services";
import { Services } from "../../../Enums/services";
const { addService, deleteOne } = apiServices;

export const addServiceSelectedToClient = async (
  clientId: any,
  data: any[],
  itemsSelected: string[],
  servicesDefaultValues: any[]
) => {
  try {
    let response;
    if (itemsSelected?.length > 0) {
      response = itemsSelected.map(async (item: string) => {
        const itemAlreadyExists = servicesDefaultValues.find((element: any) =>
          element.service.name === "Otros servicios"
            ? element.service.serviceType === item
            : element.service.name === item
        );
        const itemFounded = data.find((element: any) => element.label === item);
        if (!itemAlreadyExists && itemFounded) {
          return await addService(clientId, itemFounded.id);
        }
      });
    }
    return Promise.resolve(response);
  } catch (_) {
    Promise.reject();
  }
};

export const deleteServiceSelected = (
  itemsSelected: string[],
  servicesDefaultValues: string[]
) => {
  try {
    let responseDeleted;
    if (itemsSelected.length > 0) {
      responseDeleted = servicesDefaultValues.map(async (item: any) => {
        const nameToFind =
          item.service.name === Services.otherServices
            ? item.service.serviceType
            : item.service.name;
        const itemExists = itemsSelected.includes(nameToFind);
        if (!itemExists) {
          return await deleteOne(item.id);
        }
      });
    } else {
      responseDeleted = servicesDefaultValues.map(async (item: any) => {
        return await deleteOne(item.id);
      });
    }
    return Promise.resolve(responseDeleted);
  } catch (_) {
    return Promise.reject();
  }
};

import { createStyles } from "@mantine/core";
import { CustomFonts } from "../../../../Components/Fonts";
import { ThemeMode } from "../../../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  label: {
    ...CustomFonts.Small(),
    fontWeight: "bold",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textsContainer: {
    marginRight: "5rem",
  },
  customInput: {
    paddingBottom: "1rem",
  },
  divider: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  inputContainer: {
    marginBottom: "0.5rem",
  },
  boxMainContainer: {
    width: "50%",
    [theme.fn.smallerThan("sm")]: {
      width: "80%",
    },
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "3rem",
    [theme.fn.smallerThan("sm")]: {
      justifyContent: "space-between",
      width: "100%",
    },
  },
  buttonAdd: {
    background: theme.colors.dark[2],
    marginRight: "2rem",
    "&:hover": {
      background: theme.colors.dark[2],
    },
  },
  buttonCancel: {
    borderColor: theme.colors.dark[2],
    color: theme.colors.dark[2],
  },
  text: {
    ...CustomFonts.Small(),
    fontWeight: "initial",
    fontSize: "0.8rem",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
  },
  inputWasteContainer: {
    marginTop: "1rem",
  },
  buttonDelete: {
    backgroundColor: theme.colors.red,
    position: "absolute",
    right: "1rem",
    bottom: "2.5rem",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: theme.colors.red,
    },
    [theme.fn.smallerThan("sm")]: {
      backgroundColor: theme.colors.red,
      display: "flex",
      position: "relative",
      marginTop: "8rem",
      marginLeft: "3rem",
    },
  },
  buttonsFooter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  loader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
  },
}));

import { createStyles } from "@mantine/core";
import { ThemeMode } from "../../Enums/themeMode";

export const useStyles = createStyles((theme, _params, getRef) => {
  const icon = getRef("icon");

  return {
    navbar: {
      zIndex: 9,
      backgroundColor:
        theme.colorScheme === ThemeMode.DARK ? theme.colors.dark[0] : theme.white,
    },
    title: {
      textTransform: "uppercase",
      letterSpacing: -0.25,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm,
      color:
        theme.colorScheme === ThemeMode.DARK
          ? theme.colors.dark[1]
          : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,
      marginBottom: 5,
      
      "&:hover": {
        backgroundColor:
          theme.colorScheme === ThemeMode.DARK
            ? theme.colors.dark[6]
            : theme.colors.gray[0],
        color: theme.colorScheme === ThemeMode.DARK ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === ThemeMode.DARK ? theme.white : theme.black,
        },
      },
    },

    linkIcon: {
      ref: icon,
      color:
        theme.colorScheme === ThemeMode.DARK
          ? theme.colors.dark[2]
          : theme.colors.gray[6],
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      "&, &:hover": {
        backgroundColor: theme.fn.variant({
          variant: "light",
          color: theme.primaryColor,
        }).background,
        color: theme.fn.variant({ variant: "light", color: theme.white }).color,
        [`& .${icon}`]: {
          color: theme.fn.variant({
            variant: "light",
            color: theme.primaryColor,
          }).color,
        },
      },
    },

    footer: {
      borderTop: `1px solid ${
        theme.colorScheme === ThemeMode.DARK
          ? theme.colors.dark[4]
          : theme.colors.gray[3]
      }`,
      paddingTop: theme.spacing.md,
    },
    iconItemNavbar: {
      marginRight: 10,
    },
  };
});

import { Box, Text } from "@mantine/core";
import { useStyles } from "./styles";
import { format } from "date-fns";
import { AlertCircle } from "tabler-icons-react";

const Countdown = ({ type, date }: { type: string; date: string }) => {
  const { classes } = useStyles();
  const dateFormatted = format(new Date(date), "dd/MM/yyyy");
  const hourFormatted = format(new Date(date), "HH:mm");

  return (
    <Box className={classes.boxMainContainer}>
      <Text className={classes.timerText}>
        <AlertCircle className={classes.icon} />
        El {type} será eliminado de manera definitiva el {dateFormatted} a las{" "}
        {hourFormatted} hs
      </Text>
    </Box>
  );
};

export default Countdown;

import { Navbar, useMantineTheme } from "@mantine/core";
import { useStyles } from "./styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faUsers,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useKeycloak } from "@react-keycloak/web";
import { decodeJwt } from "jose";

export const tabs = [
  { icon: faUsers, link: "/", label: "Clientes" },
  { icon: faUserPlus, link: "/usuarios", label: "Usuarios" },
  { icon: faBookOpen, link: "/servicios", label: "Servicios" },
];

export const tabsAdmin = [{ icon: faUsers, link: "/", label: "Usuarios" }];

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useMantineTheme();
  let activeTab = `/${location.pathname.split("/")[1]}`;
  if (activeTab === "/clientes") activeTab = "/";
  if (activeTab === "/cliente") activeTab = "/";
  if (activeTab === "/usuario") activeTab = "/usuarios";

  const {
    keycloak: { token },
  }: any = useKeycloak();
  const tokenDecoded = decodeJwt(token);
  const roles: any = tokenDecoded?.realm_access || undefined;
  const isAuthorized =
    roles !== undefined
      ? // eslint-disable-next-line array-callback-return
        roles?.roles?.find((e: any) => e === "superadmin")
      : false;
  const { classes, cx } = useStyles();

  const links = (array: any[]) =>
    array.map((item) => (
      <Link
        className={cx(classes.link, {
          [classes.linkActive]: item.link === activeTab,
        })}
        style={{
          backgroundColor:
            activeTab === item.link
              ? "#27A536"
              : theme.colorScheme === "dark"
              ? "#1C2939"
              : "transparent",
        }}
        to={item.link}
        key={item.label}
        onClick={(_) => {
          navigate(item.link);
          
        }}
      >
        <FontAwesomeIcon
          fontSize={18}
          icon={item.icon}
          style={{
            color:
              activeTab === item.link
                ? theme.colors.dark[1]
                : theme.colorScheme === "dark"
                ? theme.colors.dark[1]
                : "#6D6D6D",
          }}
          className={classes.iconItemNavbar}
        />
        <span
          style={{
            color:
              activeTab === item.link
                ? "#FFF"
                : theme.colorScheme === "dark"
                ? theme.colors.dark[1]
                : "#6D6D6D",
          }}
        >
          {item.label}
        </span>
      </Link>
    ));

  return (
    <Navbar
      height={"120vh"}
      width={{ sm: 250 }}
      p="md"
      className={classes.navbar}
    >
      <Navbar.Section grow>
        {links(isAuthorized ? tabs : tabsAdmin)}
      </Navbar.Section>
    </Navbar>
  );
};

export default Sidebar;

import {
  Box,
  Button,
  Select,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { InfoCircle } from "tabler-icons-react";
import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import { rolesList } from "../../Views/utils";
import { format } from "date-fns";
import { useViewportSize } from "@mantine/hooks";
import { ThemeMode } from "../../../../Enums/themeMode";
import { Protected, Roles } from "../../../../Navigation/Protected";

type IEditUserForm = {
  form: any;
  isSubmitting: boolean;
  setIsRestorePasswordModalVisible: (value: boolean) => void;
  setIsDeleteModalVisible: (value: boolean) => void;
};

const EditUserForm = ({
  form,
  isSubmitting,
  setIsRestorePasswordModalVisible,
  setIsDeleteModalVisible,
}: IEditUserForm) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const { width } = useViewportSize();
  const inputWidth = width > 700 ? 530 : 290;
  return (
    <>
      <Box className={classes.boxItemsContainer}>
        <TextInput
          label={t("nameLabel")}
          disabled={isSubmitting}
          withAsterisk
          required
          {...form.getInputProps("firstName")}
          size="sm"
          inputWrapperOrder={["label", "input", "description", "error"]}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isSubmitting
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isSubmitting
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
        <TextInput
          size="sm"
          label={t("lastnameLabel")}
          disabled={isSubmitting}
          withAsterisk
          required
          inputWrapperOrder={["label", "input", "description", "error"]}
          {...form.getInputProps("lastName")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isSubmitting
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isSubmitting
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
      </Box>
      <Box className={classes.boxItemsContainer}>
        <TextInput
          size="sm"
          label={t("phoneLabel")}
          disabled={isSubmitting}
          inputWrapperOrder={["label", "input", "description", "error"]}
          {...form.getInputProps("phone")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isSubmitting
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isSubmitting
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
        <TextInput
          size="sm"
          label={t("emailLabel")}
          disabled={isSubmitting}
          inputWrapperOrder={["label", "input", "description", "error"]}
          {...form.getInputProps("email")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isSubmitting
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isSubmitting
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
      </Box>
      <Box className={classes.boxItemsContainer}>
        <TextInput
          size="sm"
          label={t("position")}
          disabled={isSubmitting}
          inputWrapperOrder={["label", "input", "description", "error"]}
          {...form.getInputProps("position")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isSubmitting
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isSubmitting
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
        <Box
          style={{
            width: inputWidth,
            marginRight: "1.7rem",
          }}
        >
          <Select
            data={rolesList}
            disabled={isSubmitting}
            label={"Rol"}
            required
            errorMessage={form?.errors?.rol || false}
            {...form.getInputProps("rol")}
            styles={(theme) => ({
              label: {
                color:
                  theme.colorScheme === ThemeMode.DARK
                    ? theme.colors.dark[1]
                    : theme.colors.light[1],
              },
              input: {
                width: inputWidth,
                color: theme.colorScheme === ThemeMode.DARK ? "#FFF" : "#000",
                border: isSubmitting
                  ? `0.5px solid ${theme.colors.dark[4]}`
                  : theme.colorScheme === ThemeMode.DARK
                  ? `1px solid ${theme.colors.dark[3]}`
                  : "0.5px solid",
                backgroundColor:
                  theme.colorScheme === ThemeMode.DARK
                    ? "transparent"
                    : theme.colors.light[0],
                "&:focus-within": {
                  borderColor: theme.colors.green[7],
                },
              },
            })}
          />
        </Box>
      </Box>
      <Protected roles={[Roles.SUPERADMIN]} route={false}>
        <Button
          variant="outline"
          className={classes.resetPassword}
          disabled={isSubmitting}
          onClick={() => setIsRestorePasswordModalVisible(true)}
        >
          <Text>{t("resetPassword")}</Text>
        </Button>
      </Protected>
      <Box className={classes.footerButtons}>
        <Box className={classes.buttonsContainer}>
          <Button
            className={classes.buttonAdd}
            type={"submit"}
            disabled={isSubmitting}
          >
            <Text>{isSubmitting ? t("loading") : t("save")}</Text>
          </Button>
          <Button
            variant="outline"
            className={classes.buttonCancel}
            onClick={() => navigate(-1)}
            disabled={isSubmitting}
          >
            <Text>{t("cancelOption")}</Text>
          </Button>
        </Box>
        <Protected roles={[Roles.SUPERADMIN]} route={false}>
          <Box className={classes.deleteRowContainer}>
            <Box className={classes.boxWarningContainer}>
              <InfoCircle
                size={width > 700 ? "1.5rem" : "5rem"}
                color={theme.colors.gray[5]}
              />
              <Text className={classes.deleteWarningText}>
                <strong>{t("warning")}:</strong>{" "}
                {form.values.toDeleteDate !== ""
                  ? `${t("warningRestoreUserDescription")} ${format(
                      new Date(form.values.toDeleteDate),
                      "dd/MM/yyyy"
                    )}`
                  : t("warningDescription")}
              </Text>
            </Box>
            <Button
              variant={form.values.toDeleteDate !== "" ? "subtle" : "filled"}
              className={
                form.values.toDeleteDate !== ""
                  ? classes.buttonRestore
                  : classes.buttonDelete
              }
              disabled={isSubmitting}
              onClick={() => setIsDeleteModalVisible(true)}
            >
              <Text>
                {form.values.toDeleteDate !== ""
                  ? t("restoreOption")
                  : t("delete")}
              </Text>
            </Button>
          </Box>
        </Protected>
      </Box>
    </>
  );
};

export default EditUserForm;

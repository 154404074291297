import { createStyles } from "@mantine/core";
import { CustomFonts } from "../../../../Components/Fonts";
import { ThemeMode } from "../../../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  boxContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    display: "flex",
    [theme.fn.smallerThan("sm")]: {
      display: "grid",
    },
  },
  buttonsContainer: {
    marginTop: "2rem",
    display: "flex",
    flexDirection: "row",
  },
  buttonLeftContainer: {
    display: "flex",
    flexDirection: "row",
    marginRight: "5rem",
    alignSelf: "center",
  },
  buttonAdd: {
    backgroundColor: theme.colors.dark[2],
    width: 130,
    marginRight: "2rem",
    ":hover": {
      backgroundColor: theme.colors.dark[2],
    },
  },
  buttonCancel: {
    borderColor: theme.colors.dark[2],
    color: theme.colors.dark[2],
    width: 130,
    marginRight: "2rem",
    ":hover": {
      borderColor: theme.colors.dark[2],
    },
  },
  deleteRowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  deleteWarningText: {
    ...CustomFonts.Small(),
    fontWeight: "normal",
    lineHeight: "17px",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[3]
        : theme.colors.gray[7],
    marginLeft: "1rem",
    marginRight: "2rem",
  },
  buttonDelete: {
    backgroundColor: theme.colors.red[9],
    color: theme.colors.light[0],
    width: "auto",
    ":hover": {
      backgroundColor: theme.colors.red[9],
    },
  },
  buttonRestore: {
    borderColor: theme.colors.green,
    color: theme.colors.green,
    width: "auto",
    ":hover": {
      borderColor: theme.colors.green,
    },
  },
}));

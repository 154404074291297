import { Route, Routes } from "react-router-dom";
import Clients from "../Modules/Clients/Views";
import ClientDetail from "../Modules/Clients/Views/clientDetail";
import CreateClient from "../Modules/Clients/Views/createClient";
import CreateUser from "../Modules/Clients/Views/createUser";
import EditUser from "../Modules/Clients/Views/editUser";
import Dashboard from "../Modules/Dashboard/Views";
import NotAuthorized from "../Modules/NotAuthorized/Views";
import NotFound from "../Modules/NotFound/Views";
import Services from "../Modules/Services/Views";
import Settings from "../Modules/Settings/Views";
import Users from "../Modules/Users/Views";
import ManageUsers from "../Modules/Users/Views/manage";
import ManageService from "../Modules/Services/Views/manage";
import { Protected, RolePolicy, Roles } from "./Protected";
import { useGetRoleName } from "../Hooks/useGetRoleName";

export const RoutesNavigation = () => {
  const roleName = useGetRoleName();

  return (
    <Routes>
      <Route path="/" element={<Dashboard />}>
        {roleName === 'admin' ? (
          <Route
          path=""
          element={
            <Protected
              route
              roles={[Roles.ADMIN]}
              children={<ClientDetail />}
            />
          }
        />
        ):(
          <Route
            path=""
            element={
              <Protected
                route
                roles={[Roles.SUPERADMIN]}
                children={<Clients />}
              />
            }
          />
        )}
        <Route
          path="/clientes/crear-clientes"
          element={
            <Protected
              route
              roles={[Roles.SUPERADMIN]}
              children={<CreateClient />}
            />
          }
        />
        <Route
          path="cliente/:clientId"
          element={
            <Protected
              route
              roles={[Roles.SUPERADMIN, Roles.ADMIN]}
              policy={RolePolicy.MATCH_ANY}
              children={<ClientDetail />}
            />
          }
        />
        <Route
          path="cliente/:clientId/crear-usuario"
          element={
            <Protected
              route
              roles={[Roles.SUPERADMIN, Roles.ADMIN]}
              policy={RolePolicy.MATCH_ANY}
              children={<CreateUser />}
            />
          }
        />
        <Route
          path="cliente/:clientId/editar-usuario/:userId"
          element={
            <Protected
              route
              roles={[Roles.SUPERADMIN, Roles.ADMIN]}
              policy={RolePolicy.MATCH_ANY}
              children={<EditUser />}
            />
          }
        />
        <Route
          path="usuarios"
          element={
            <Protected route roles={[Roles.SUPERADMIN]} children={<Users />} />
          }
        />
        <Route
          path="usuario/crear-usuario"
          element={
            <Protected
              route
              roles={[Roles.SUPERADMIN]}
              children={<ManageUsers />}
            />
          }
        />
         <Route
          path="usuario/editar-usuario/:userId"
          element={
            <Protected
              route
              roles={[Roles.SUPERADMIN]}
              children={<ManageUsers />}
            />
          }
        />
        <Route
          path="servicios"
          element={
            <Protected
              route
              roles={[Roles.SUPERADMIN]}
              children={<Services />}
            />
          }
        />
        <Route
          path="servicios/crear-servicio"
          element={
            <Protected
              route
              roles={[Roles.SUPERADMIN]}
              children={<ManageService />}
            />
          }
        />
        <Route
          path="servicios/editar-servicio/:id"
          element={
            <Protected
              route
              roles={[Roles.SUPERADMIN]}
              children={<ManageService />}
            />
          }
        />
        <Route
          path="configuracion"
          element={
            <Protected
              route
              roles={[Roles.SUPERADMIN, Roles.ADMIN]}
              children={<Settings />}
              policy={RolePolicy.MATCH_ANY}
            />
          }
        />
        <Route
          path=""
          element={
            <Protected
              route
              roles={[Roles.SUPERADMIN]}
              children={<ClientDetail />}
            />
          }
        />
        <Route
          path="cliente/:clientId"
          element={
            <Protected
              route
              roles={[Roles.SUPERADMIN]}
              children={<ClientDetail />}
            />
          }
        />
        <Route
          path="cliente/editar-cliente/:clientId"
          element={
            <Protected
              route
              roles={[Roles.SUPERADMIN]}
              children={<CreateClient />}
            />
          }
        />
        <Route
          path="cliente/:clientId/crear-usuario"
          element={
            <Protected
              route
              roles={[Roles.SUPERADMIN]}
              children={<CreateUser />}
            />
          }
        />
        <Route
          path="cliente/:clientId/editar-usuario/:userId"
          element={
            <Protected
              route
              roles={[Roles.SUPERADMIN]}
              children={<EditUser />}
            />
          }
        />
        <Route
          path="configuracion"
          element={
            <Protected
              route
              roles={[Roles.SUPERADMIN, Roles.ADMIN]}
              policy={RolePolicy.MATCH_ANY}
              children={<Settings />}
            />
          }
        />
      </Route>
      <Route path="no-autorizado" element={<NotAuthorized />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

import { Box, Button, Text, TextInput, useMantineTheme } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { InfoCircle } from "tabler-icons-react";
import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import { useViewportSize } from "@mantine/hooks";
import { ThemeMode } from "../../../../Enums/themeMode";

type ICreateClientForm = {
  clientId: string | undefined;
  isLoading: boolean;
  form: any;
  setIsDeleteModalVisible: (value: boolean) => void;
};

const CreateClientForm = ({
  clientId,
  isLoading,
  form,
  setIsDeleteModalVisible,
}: ICreateClientForm) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { width } = useViewportSize();
  const inputWidth = width > 700 ? 530 : 290;
  const theme = useMantineTheme();

  return (
    <Box>
      <TextInput
        label={t("companyName")}
        withAsterisk
        name={"name"}
        size="sm"
        disabled={isLoading}
        width={inputWidth}
        inputWrapperOrder={["label", "input", "description", "error"]}
        {...form.getInputProps("name")}
        styles={(theme) => ({
          label: {
            color:
              theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
          },
          input: {
            width: inputWidth,
            border: isLoading
              ? `0.5px solid ${theme.colors.dark[4]}`
              : theme.colorScheme === ThemeMode.DARK
              ? `1px solid ${theme.colors.dark[3]}`
              : "0.5px solid",
            backgroundColor:
              theme.colorScheme === ThemeMode.DARK
                ? "transparent"
                : theme.colors.light[0],
            marginRight: "2rem",
            "&:focus-within": {
              borderColor: theme.colors.green[7],
            },
            color: isLoading
              ? "#000!important"
              : theme.colorScheme === ThemeMode.DARK
              ? theme.colors.dark[1]
              : theme.colors.light[1],
          },
        })}
      />
      <Box className={classes.boxContainer}>
        <TextInput
          label={t("responsibleName")}
          name="responsible_name"
          size="sm"
          disabled={isLoading}
          width={inputWidth}
          inputWrapperOrder={["label", "input", "description", "error"]}
          {...form.getInputProps("responsible_name")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isLoading
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isLoading
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
        <TextInput
          label={t("lastNameResponsibleName")}
          name="responsible_lastname"
          size="sm"
          disabled={isLoading}
          width={inputWidth}
          inputWrapperOrder={["label", "input", "description", "error"]}
          {...form.getInputProps("responsible_lastname")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isLoading
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isLoading
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
      </Box>
      <Box className={classes.boxContainer}>
        <TextInput
          label={t("phoneLabel")}
          name="phone"
          size="sm"
          disabled={isLoading}
          width={inputWidth}
          inputWrapperOrder={["label", "input", "description", "error"]}
          {...form.getInputProps("phone")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isLoading
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isLoading
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
        <TextInput
          label={t("emailLabel")}
          required
          name="email"
          size="sm"
          disabled={isLoading}
          width={inputWidth}
          inputWrapperOrder={["label", "input", "description", "error"]}
          {...form.getInputProps("email")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isLoading
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isLoading
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
      </Box>
      <Box className={classes.boxContainer}>
        <TextInput
          label={t("street")}
          name="address"
          size="sm"
          disabled={isLoading}
          width={inputWidth}
          inputWrapperOrder={["label", "input", "description", "error"]}
          {...form.getInputProps("address")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isLoading
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isLoading
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
        <TextInput
          label={t("CIF")}
          name="cif"
          size="sm"
          disabled={isLoading}
          width={inputWidth}
          inputWrapperOrder={["label", "input", "description", "error"]}
          {...form.getInputProps("cif")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isLoading
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isLoading
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
      </Box>
      <Box className={classes.buttonsContainer}>
        <Box className={classes.buttonLeftContainer}>
          <Button
            type={form.values.toDeleteDate === "" ? "submit" : "button"}
            variant="filled"
            className={classes.buttonAdd}
            disabled={isLoading}
            onClick={() => {
              if (form.values.toDeleteDate !== "") {
                setIsDeleteModalVisible(true);
              }
            }}
          >
            {isLoading
              ? t("loading")
              : clientId
              ? form.values.toDeleteDate !== ""
                ? t("restoreOption")
                : t("modify")
              : t("add")}
          </Button>
          <Button
            onClick={() => navigate(-1)}
            variant="outline"
            disabled={isLoading}
            className={classes.buttonCancel}
          >
            {t("cancelOption")}
          </Button>
        </Box>
        {clientId && form.values.toDeleteDate === "" && (
          <Box className={classes.deleteRowContainer}>
            <InfoCircle size={"3rem"} color={theme.colors.gray[5]} />
            <Text className={classes.deleteWarningText}>
              <strong>{t("warning")}:</strong> {t("warningClientDescription")}
            </Text>
            <Button
              variant={"filled"}
              className={classes.buttonDelete}
              disabled={isLoading}
              onClick={() => setIsDeleteModalVisible(true)}
            >
              <Text>{t("delete")}</Text>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default CreateClientForm;

import { createStyles } from "@mantine/core";
import { CustomFonts } from "../../Fonts";

export const useStyles = createStyles((theme) => ({
  modalContainer: {
    zIndex: 9999,
  },
  title: {
    ...CustomFonts.SubTitle(),
    fontWeight: "bold",
  },
  userName: {
    ...CustomFonts.BodyNormal(),
    fontWeight: "lighter",
  },
  generatePasswordContainer: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  generatePasswordText: {
    marginLeft: "2rem",
    ...CustomFonts.Small(),
    fontWeight: "lighter",
    lineHeight: "18px",
  },
  buttonContainer: {
    marginTop: "2rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
  },
  buttonCancel: {
    marginRight: "1rem",
    borderColor: theme.colors.green,
    color: theme.colors.green,
  },
  buttonSubmit: {
    backgroundColor: theme.colors.green,
    "&: hover": {
      backgroundColor: theme.colors.green,
    },
  },
  passwordInputContainer: {
    marginLeft: "2rem",
  },
  userChangePasswordText: {
    marginTop: "0.5rem",
  },
  passwordInput: {
    width: "90%",
  },
}));

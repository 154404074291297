import instance from ".";
import { RoutePrefix } from "../Constants";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  updateActivation: function (id: string, isActive: boolean) {
    return instance.put(`${RoutePrefix.USERS}/${id}/activate`, {
      isActivated: isActive,
    });
  },
  create: function (user: any) {
    return instance.post(RoutePrefix.USERS, user);
  },
  deleteOne: function (id: string, toDelete: boolean) {
    return instance.delete(`${RoutePrefix.USERS}/${id}`, {
      data: {
        toDeleteDate: toDelete,
      },
    });
  },
  getOne: function (id: string | undefined) {
    return instance.get(`${RoutePrefix.USERS}/${id}`);
  },
  update: function (userId: string | undefined, obj: any) {
    return instance.put(`${RoutePrefix.USERS}/${userId}`, obj);
  },
  updateStatus: function (id: string, status: boolean) {
    return instance.put(
      `${RoutePrefix.USERS}/${id}${RoutePrefix.TOGGLE_STATUS}`,
      {
        enabled: status,
      }
    );
  },
  resetPasswordDefault: function (id: string) {
    return instance.put(`${RoutePrefix.USERS}/${id}/reset-password-default`, {
      temporary: true
    });
  },
  resetPassword: function (id: string, password?: string) {
    return instance.put(`${RoutePrefix.USERS}/${id}/reset-password`, {
      password: password,
      temporary: true
    });
  },
  getAll: function (
    page: number,
    limit: number,
    activeOrNot: boolean,
    keyword?: string
  ) {
    if (keyword) {
      return instance.get(
        `${RoutePrefix.USERS}/paginate?search=${keyword}&limit=${limit}&page=${page}&active=${activeOrNot}`
      );
    } else {
      return instance.get(
        `${RoutePrefix.USERS}/paginate?search=&limit=${limit}&page=${page}&active=${activeOrNot}`
      );
    }
  },
};

import {
  Header,
  Group,
  Text,
  Divider,
  Box,
  Burger,
  Drawer,
  ScrollArea,
  Image,
  ActionIcon,
  useMantineTheme,
  Button,
} from "@mantine/core";
import { useDisclosure, useViewportSize } from "@mantine/hooks";
import { useKeycloak } from "@react-keycloak/web";
import { decodeJwt } from "jose";
import { useState } from "react";
import { DotsVertical, UserCircle } from "tabler-icons-react";
import {
  faBookOpen,
  faUsers,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import Logo from "../../Assets/Images/Svg/Logo.svg";
import { useTranslation } from "react-i18next";
import SwitchTheme from "../SwitchTheme";
import { useStyles } from "./styles";
import SwitchThemeMobile from "../SwitchTheme/SwitchThemeMobile";
import { ThemeMode } from "../../Enums/themeMode";
import { useGetRoleName } from "../../Hooks/useGetRoleName";
import Menu from "./Menu";
import { Link } from "react-router-dom";
import LogOutModal from "../CustomModals/LogOutModal";

export const tabs = [
  { icon: faUsers, link: "/", label: "Clientes" },
  { icon: faUserPlus, link: "/usuarios", label: "Usuarios" },
  { icon: faBookOpen, link: "/servicios", label: "Servicios" },
  { icon: faBookOpen, link: "/configuracion", label: "Configuración" },
  { icon: faBookOpen, link: "", label: "Cerrar sesión" },
];

const CustomHeader = () => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const role = useGetRoleName();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const { width } = useViewportSize();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const {
    keycloak: { token },
  }: any = useKeycloak();
  const tokenDecoded: any = decodeJwt(token);

  return (
    <Box className={classes.mainContainer}>
      <Header height={60} px="md">
        <Group position="apart" sx={{ height: "100%" }}>
          <Group className={classes.mobileHeaderContainer}>
            <Burger
              opened={drawerOpened}
              onClick={toggleDrawer}
              className={classes.hiddenDesktop}
            />
            <Box
              className={
                width > 700 ? classes.logoImage : classes.logoImageResponsive
              }
            >
              <Image width={"auto"} src={Logo} />
            </Box>
            <SwitchThemeMobile />
          </Group>
          <Group>
            <Box
              className={
                width > 700 ? classes.logoImage : classes.logoImageResponsive
              }
            >
              <Image width={"auto"} src={Logo} className={classes.logo} />
            </Box>
          </Group>
          <Group className={classes.hiddenMobile}>
            <Box className={classes.boxUserInfoContainer}>
              <SwitchTheme />
              <UserCircle color={theme.colors.dark[2]} size={40} />
              <Box className={classes.userNameContainer}>
                <Text
                  weight={600}
                  color={
                    theme.colorScheme === ThemeMode.DARK
                      ? theme.colors.dark[1]
                      : theme.colors.light[1]
                  }
                  className={classes.usernameText}
                >
                  {tokenDecoded.name}
                </Text>
                <Text
                  weight={200}
                  color={
                    theme.colorScheme === ThemeMode.DARK
                      ? theme.colors.dark[1]
                      : theme.colors.light[1]
                  }
                >
                  {role}
                </Text>
              </Box>
              <ActionIcon onClick={() => setToggleMenu(!toggleMenu)}>
                <DotsVertical />
              </ActionIcon>
            </Box>
          </Group>
          {toggleMenu && (
            <Menu
              setIsLogoutModalVisible={setIsLogoutModalVisible}
              setToggleMenu={setToggleMenu}
            />
          )}
        </Group>
      </Header>
      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        className={classes.sidebarMenu}
      >
        <ScrollArea sx={{ height: "calc(100vh - 60px)" }} mx="-md">
          <div>
            <Link to={"/"} className={classes.link} onClick={closeDrawer}>
              {t("clients")}
            </Link>
            <Divider
              my="sm"
              color={theme.colorScheme === ThemeMode.DARK ? "dark.5" : "gray.1"}
            />
            <Link to={"/usuarios"} className={classes.link} onClick={closeDrawer}>
              {t("users")}
            </Link>
            <Divider
              my="sm"
              color={theme.colorScheme === ThemeMode.DARK ? "dark.5" : "gray.1"}
            />
            <Link to={"/servicios"} className={classes.link} onClick={closeDrawer}>
              {t("services")}
            </Link>
            <Divider
              my="sm"
              color={theme.colorScheme === ThemeMode.DARK ? "dark.5" : "gray.1"}
            />
            <Link to={"/configuracion"} className={classes.link} onClick={closeDrawer}>
              {t("settings")}
            </Link>
            <Divider
              my="sm"
              color={theme.colorScheme === ThemeMode.DARK ? "dark.5" : "gray.1"}
            />
            <Button
              className={classes.buttonLogout}
              onClick={() => {
                keycloak.logout();
                closeDrawer();
              }}
            >
              {t("logout")}
            </Button>
          </div>
        </ScrollArea>
      </Drawer>
      <LogOutModal
        isModalVisible={isLogoutModalVisible}
        onCloseModal={() => setIsLogoutModalVisible(false)}
        title={t("logoutMessage")}
        buttonCancelFunction={() => setIsLogoutModalVisible(false)}
        buttonConfirmFunction={() => keycloak.logout()}
        buttonCancelText={"cancelOption"}
        buttonConfirmText={"quitOption"}
      />
    </Box>
  );
};

export default CustomHeader;

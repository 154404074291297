import {
  Text,
  Container,
  LoadingOverlay,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useKeycloak } from "@react-keycloak/web";
import { decodeJwt } from "jose";
import { useEffect, useState } from "react";
import CustomHeaderBreadcrumbs from "../../../Components/CustomHeaderBreadcrumbs";
import CustomModal from "../../../Components/CustomModals/Modal/Modal";
import { useTranslation } from "react-i18next";
import { getUserDataById, updateUser } from "../controllers/settingsController";
import { useStyles } from "./styles";
import OutletContainer from "../../../Components/OutletContainer";
import SettingsForm from "../Components/SettingsForm";
import { ThemeMode } from "../../../Enums/themeMode";

const Settings = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState({
    isVisible: false,
    success: false,
  });
  const {
    keycloak: { token },
  }: any = useKeycloak();
  const tokenDecoded = decodeJwt(token);
  const userId: string | undefined = tokenDecoded?.sub;

  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      position: "",
      rol: "",
    },

    validate: {
      rol: (value) => (value ? null : "Campo requerido"),
      email: (value) => {
        if (/^\S+@\S+$/.test(value)) {
          return null;
        } else if (value.length === 0) {
          return "Campo requerido";
        } else {
          return "Email invalido";
        }
      },
    },
  });

  const getData = async () => {
    setIsLoading(true);
    const { data } = await getUserDataById(userId);
    form.setValues({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      position: data.position,
      rol: data.rol,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);
    const { error } = await updateUser(userId, values);
    setIsModalVisible({
      isVisible: true,
      success: error === null,
    });
    setIsSubmitting(false);
  };

  return (
    <>
      <CustomHeaderBreadcrumbs
        title={`${tokenDecoded?.name}`}
        description={"settingsDescription"}
      />
      <OutletContainer>
        <LoadingOverlay
          visible={isLoading}
          loaderProps={{ color: "green" }}
          overlayColor={
            theme.colorScheme === ThemeMode.DARK
              ? theme.colors.dark[0]
              : theme.colors.dark[1]
          }
        />
        <Container className={classes.mainContainer}>
          <Text className={classes.userInfoTitle}>{t("userInfoTitle")}</Text>
          <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <SettingsForm isSubmitting={isSubmitting} form={form} />
          </form>
        </Container>
      </OutletContainer>
      <CustomModal
        isModalVisible={isModalVisible.isVisible}
        onCloseModal={() =>
          setIsModalVisible((prev) => ({
            ...prev,
            isVisible: false,
          }))
        }
        title={isModalVisible.success ? "userEdited" : "userEditedError"}
        success={isModalVisible.success}
      />
    </>
  );
};

export default Settings;

import { useStyles } from "./styles";
import { Divider, Text } from "@mantine/core";
import CustomHeaderBreadcrumbs from "../../../../Components/CustomHeaderBreadcrumbs";
import { useTranslation } from "react-i18next";
import CreateServiceForm from "../../Components/CreateServiceForm";
import { useParams } from "react-router-dom";
import OutletContainer from "../../../../Components/OutletContainer";

const ManageService = () => {
  const { id } = useParams();
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <CustomHeaderBreadcrumbs
        title={id ? "editServices" : `createServices`}
        description={"abmServices"}
      />
      <OutletContainer>
        <Text className={classes.titleText}>{t("serviceInfoTitle")}</Text>
        <Divider className={classes.divider} />
        <CreateServiceForm />
      </OutletContainer>
    </>
  );
};

export default ManageService;

import {
  ActionIcon,
  Box,
  Table,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Pencil, Trash } from "tabler-icons-react";
import { useStyles } from "./styles";
import { deleteService } from "../../Controller/services";
import { useState } from "react";
import { ThemeMode } from "../../../../Enums/themeMode";
import CustomModal from "../../../../Components/CustomModals/Modal/Modal";

const ServicesTable = ({
  servicesData,
  setIsDeleteModalVisible,
  setServiceDeletedStatus,
  isDeleteModalVisible,
  getData,
}: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [serviceSelected, setServiceSelected] = useState<string>("");

  const onDeleteService = async () => {
    setIsDeleteModalVisible(false);
    const response = await deleteService(serviceSelected);
    if (response) {
      getData();
    }
    setServiceDeletedStatus({
      isVisible: true,
      success: response,
    });
  };

  return (
    <>
      <Table highlightOnHover className={classes.tableResponsive}>
        <thead className={classes.tableHeader}>
          <tr>
            <th>
              <Text className={classes.normalText}>{t("serviceName")}</Text>
            </th>
            <th>
              <Text className={classes.normalText}>{t("servicesType")}</Text>
            </th>
            <th>
              <Text className={classes.normalText}>{t("typeOfWaste")}</Text>
            </th>
            <th>
              <Text className={classes.normalText}>{t("actionsLabel")}</Text>
            </th>
          </tr>
        </thead>
        {servicesData?.length > 0 ? (
          <tbody className={classes.bodyContainer}>
            {servicesData?.map((item: any) => (
              <tr key={item.id.toString()}>
                <td>
                  <Text className={classes.normalText}>
                    {item?.serviceName}
                  </Text>
                </td>
                <td>
                  {item.serviceType !== "" && (
                    <>
                      {typeof item.serviceType === 'string' ? (
                        <Text className={classes.serviceTypeText}>
                        {item?.serviceType}
                      </Text>
                      ):(
                        <>
                          {item?.serviceType?.length > 0 && (
                    <>
                      {item?.serviceType?.map((item: string, index: number) => {
                        return index <= 0 && (
                          <Text
                            key={`${item}_${index}`}
                            className={classes.serviceTypeText}
                          >
                            {item}
                          </Text>
                        )
                      })}
                      {item?.serviceType?.length > 1 && (
                        <Tooltip
                          label={
                            <>
                              {item?.serviceType
                                .slice(1)
                                .map((item: any, index: any) => (
                                  <Text key={`${item}_${index}`}>{item}</Text>
                                ))}
                            </>
                          }
                        >
                          <Text className={classes.serviceTypeText}>
                            +{item?.serviceType.length - 1}
                          </Text>
                        </Tooltip>
                      )}
                    </>
                  )}
                        </>
                      ) }
                    </>
                  )}
                </td>
                <td>
                  {item?.wasteType?.length > 0 && (
                    <>
                      {item?.wasteType?.map((item: string, index: number) => {
                        return index <= 2 && (
                          <Text
                            key={`${item}_${index}`}
                            className={classes.serviceTypeText}
                          >
                            {item}
                          </Text>
                        )
                      })}
                      {item?.wasteType?.length > 3 && (
                        <Tooltip
                          label={
                            <>
                              {item?.wasteType
                                .slice(3)
                                .map((item: any, index: any) => (
                                  <Text key={`${item}_${index}`}>{item}</Text>
                                ))}
                            </>
                          }
                        >
                          <Text className={classes.serviceTypeText}>
                            +{item?.wasteType.length - 3}
                          </Text>
                        </Tooltip>
                      )}
                    </>
                  )}
                </td>
                <td>
                  <Box className={classes.actionsContainer}>
                    <ActionIcon
                      disabled={!item.enableToEdit}
                      className={classes.iconActionContainer}
                      onClick={() => navigate(`editar-servicio/${item.id}`)}
                    >
                      <Pencil
                        size={15}
                        color={
                          theme.colorScheme === ThemeMode.DARK
                            ? theme.colors.dark[0]
                            : theme.colors.light[0]
                        }
                      />
                    </ActionIcon>
                    <ActionIcon
                      disabled={!item.enableToEdit}
                      className={classes.iconTrashContainer}
                      onClick={() => {
                        setServiceSelected(item.id);
                        setIsDeleteModalVisible(true);
                      }}
                    >
                      <Trash
                        size={15}
                        color={
                          theme.colorScheme === ThemeMode.DARK
                            ? theme.colors.dark[0]
                            : theme.colors.light[0]
                        }
                      />
                    </ActionIcon>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody
            className={classes.bodyContainer}
            style={{
              display: "flex",
              marginLeft: "23rem",
            }}
          >
            <tr>
              <td>
                <Text className={classes.emptyResultsText}>
                  {t("emptyResults")}
                </Text>
              </td>
            </tr>
          </tbody>
        )}
      </Table>
      <CustomModal
        isModalVisible={isDeleteModalVisible}
        onCloseModal={() => setIsDeleteModalVisible(false)}
        description={"deleteClientDescription"}
        success={false}
        buttons
        title={"deleteServiceTitle"}
        buttonCancelText={"cancelOption"}
        buttonCancelFunction={() => setIsDeleteModalVisible(false)}
        buttonConfirmText={"deleteOption"}
        buttonConfirmFunction={onDeleteService}
      />
    </>
  );
};

export default ServicesTable;

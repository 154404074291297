import { createStyles } from "@mantine/core";
import { CustomFonts } from "../../../Components/Fonts";
import { ThemeMode } from "../../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  boxBreadcrumsContainer: {
    width: "100vw",
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[4]
        : theme.colors.light[0],
  },
  outletContainer: {
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[0]
        : theme.colors.light[0],
    position: "absolute",
    marginLeft: "1rem",
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 5,
    paddingLeft: 24,
    paddingRight: 24,
    marginTop: "-2%",
  },
  loader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
  },
  mainContainer: {
    maxWidth: "none",
    display: "flex",
    flexDirection: "column",
  },
  userInfoTitle: {
    ...CustomFonts.BodyBold(),
    fontWeight: "bold",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
  },
  boxItemsContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
  boxSelectContainer: {
    right: "1.5%",
  },
  buttonsContainer: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  buttonSave: {
    backgroundColor: theme.colors.dark[2],
    width: 130,
    marginRight: "2rem",
    ":hover": {
      backgroundColor: theme.colors.dark[2],
    },
  },
  buttonCancel: {
    borderColor: theme.colors.dark[2],
    color: theme.colors.dark[2],
    width: 130,
    marginRight: "2rem",
    ":hover": {
      borderColor: theme.colors.dark[2],
    },
  },
}));

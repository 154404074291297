import { Box, Loader } from "@mantine/core";
import { useStyles } from "./styles";

const Loading = () => {
  const { classes } = useStyles();
  const activeTheme = localStorage.getItem('color-scheme');

  return (
    <Box
      className={classes.container}
      style={{
        backgroundColor:
        activeTheme === "dark"
          ? '#16202B'
          : '#FFF',
      }}
    >
      <Loader color={"green"} className={classes.loader} />
    </Box>
  );
};

export default Loading;

import userApi from "../../../Api/users";
import { IResponse } from "../../../Types/response";
const { getOne, update } = userApi;

export const getUserDataById = async (clientId: string | undefined) => {
  const response: IResponse = { data: null, error: null };
  try {
    const { data } = await getOne(clientId);
    response.data = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.phone,
      position: data?.position,
      rol: data?.role,
    };
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const updateUser = async (
  userId: string | undefined,
  clientData: any
) => {
  const response: IResponse = { data: null, error: null };
  try {
    const objToSubmit = {
      lastName: clientData.lastName,
      firstName: clientData.firstName,
      email: clientData.email,
      attributes: {
        phone: clientData?.phone,
        position: clientData?.position,
      },
      roles: [clientData?.rol],
    };
    response.data = await update(userId, objToSubmit);
  } catch (_) {
    response.error = false;
  }
  return response;
};

import { createStyles } from "@mantine/core";
import { ThemeMode } from "../../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  boxBreadcrumsContainer: {
    width: "100vw",
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[4]
        : theme.colors.light[0],
  },
  outletContainer: {
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[0]
        : theme.colors.light[0],
    position: "absolute",
    marginLeft: "1rem",
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 5,
    paddingLeft: 24,
    paddingRight: 24,
    marginTop: "-2%",
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    marginBottom: '2rem'
  },
  loader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
  },
  notification: {
    position: "absolute",
    right: 0,
    top: "3.5rem",
  },
}));

import { createStyles } from "@mantine/core";
import { CustomFonts } from "../../../../Components/Fonts";

export const useStyles = createStyles((theme) => ({
  modal: {
    zIndex: 99999,
  },
  modalTitle: {
    ...CustomFonts.SubTitle(),
    fontWeight: "bold",
  },
  topContentContainer: {
    marginBottom: "1rem",
    marginTop: "-2rem",
  },
  bottomContentContainer: {
    marginTop: "2rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  cancelButton: {
    backgroundColor: "transparent",
    borderColor: theme.colors.dark[2],
    color: theme.colors.dark[2],
    marginRight: "1.5rem",
  },
  submitButton: {
    backgroundColor: theme.colors.dark[2],
    "&:hover": {
      backgroundColor: theme.colors.dark[2],
    },
  },
  loader: {
    alignSelf: 'center',
    display: 'flex',
    width: '100%'
  }
}));

import { createStyles } from "@mantine/core";
import { ThemeMode } from "../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "&: hover": {
      backgroundColor:
        theme.colorScheme === ThemeMode.DARK
          ? theme.colors.dark[4]
          : "transparent",
    },
    [theme.fn.largerThan("sm")]: {
      marginRight: "1rem",
      justifyContent: "space-between",
      border: "0.5px solid",
      borderRadius: 100,
      borderColor:
        theme.colorScheme === ThemeMode.DARK
          ? theme.colors.dark[0]
          : theme.colors.gray,
      backgroundColor:
        theme.colorScheme === ThemeMode.DARK
          ? theme.colors.dark[4]
          : "transparent",
    },
  },
  modeText: {
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
    paddingRight: "2rem",
  },
  icon: {
    backgroundColor: theme.colors.dark[2],
    borderRadius: "50%",
    width: 28,
    height: 28,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    position: "absolute",
    right: 5,
  },
}));

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en/en.json";
import es from "./es/es.json";

export const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
};

i18next.use(initReactI18next)
.init({
  fallbackLng: 'es',
  debug: true,
  resources,
});

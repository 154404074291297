import { useTheme } from "@emotion/react";

enum Weight {
  Regular = "regular",
  Bold = "bold",
  Font100 = "light",
  Font200 = "semiBold",
}

const fontFamily = {
  bold: "OpenSans-Bold",
  boldItalic: "OpenSans-BoldItalic",
  extraBold: "OpenSans-ExtraBold",
  extraBoldItalic: "OpenSans-ExtraBoldItalic",
  italic: "OpenSans-Italic",
  light: "OpenSans-Light",
  lightItalic: "OpenSans-LightItalic",
  medium: "OpenSans-Medium",
  mediumItalic: "OpenSans-MediumItalic",
  regular: "OpenSans-Regular",
  semiBold: "OpenSans-SemiBold",
  semiBoldItalic: "OpenSans-SemiBoldItalic",
};

const FontsTypes = {
  title: {
    // fontFamily: fontFamily.extraBold,
    fontWeight: Weight.Bold,
    fontSize: 32,
  },
  title2: {
    // fontFamily: fontFamily.extraBold,
    fontWeight: Weight.Bold,
    fontSize: 28,
  },
  subtitle: {
    // fontFamily: fontFamily.extraBold,
    fontWeight: Weight.Bold,
    fontSize: 20,
  },
  bodyBold: {
    // fontFamily: fontFamily.bold,
    fontWeight: Weight.Bold,
    fontSize: 16,
  },
  bodyNormal: {
    // fontFamily: fontFamily.regular,
    fontWeight: Weight.Regular,
    fontSize: 16,
  },
  small: {
    // fontFamily: fontFamily.medium,
    fontWeight: Weight.Regular,
    fontSize: 14,
  },
  headerBold: {
    // fontFamily: fontFamily.medium,
    fontWeight: Weight.Font200,
    fontSize: 18,
  },
};

interface IFonts {
  fontFamily?: string;
  fontWeight?: Weight | undefined | string;
  fontSize: number;
  lineHeight?: number;
  color?: string;
}

export const CustomFonts = {
  Title: (): IFonts => Font(FontsTypes.title),
  Title2: (): IFonts => Font(FontsTypes.title2),
  BodyBold: (): IFonts => Font(FontsTypes.bodyBold),
  BodyNormal: (): IFonts => Font(FontsTypes.bodyNormal),
  SubTitle: (): IFonts => Font(FontsTypes.subtitle),
  Small: (): IFonts => Font(FontsTypes.small),
  HeaderBold: (): IFonts => Font(FontsTypes.headerBold),
};

const Font = (font: IFonts): IFonts => {
  const textColor = useTheme();
  return {
    ...font,
    color: "#000",
  };
};

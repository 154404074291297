import { Box, Button, Text, useMantineTheme } from "@mantine/core";
import { ArrowLeft, Plus } from "tabler-icons-react";
import { useStyles } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeMode } from "../../Enums/themeMode";
import { useTranslation } from "react-i18next";

type ICustomHeaderBreadcrumbs = {
  title: string;
  description: string;
  showButton?: boolean;
  buttonTitle?: string;
  buttonAction?: () => void;
};

const CustomHeaderBreadcrumbs = ({
  title,
  description,
  showButton,
  buttonTitle,
  buttonAction,
}: ICustomHeaderBreadcrumbs) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const location = useLocation();
  const activePath = location.pathname;
  const navigate = useNavigate();
  const isRootPath =
    activePath !== "/" &&
    activePath !== "/usuarios" &&
    activePath !== "/servicios";

  return (
    <Box
      className={
        !isRootPath
          ? classes.boxContainer
          : classes.boxContainerWithButton
      }
    >
      <Box>
        {isRootPath && (
          <Button
            onClick={() => {
              if (!location.state || location.state === null) {
                navigate(-1);
              } else {
                navigate("/");
              }
            }}
            className={classes.buttonGoBack}
          >
            <ArrowLeft
              color={
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[0]
                  : theme.colors.dark[2]
              }
              className={classes.goBackIcon}
              size={"1.5rem"}
            />
            <Text className={classes.goBackButtonText}>{t("goBack")}</Text>
          </Button>
        )}
        <Text className={classes.titleSection}>{t(`${title}`)}</Text>
        <Text className={classes.descriptionSection}>
          {t(`${description}`)}
        </Text>
      </Box>
      {showButton && showButton && (
        <Button
          onClick={buttonAction}
          variant="light"
          className={classes.button}
        >
          <Plus
            size={15}
            color={
              theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[0]
                : theme.colors.light[0]
            }
          />
          <Text className={classes.buttonText}>{t(`${buttonTitle}`)}</Text>
        </Button>
      )}
    </Box>
  );
};

export default CustomHeaderBreadcrumbs;

import {
  ActionIcon,
  Box,
  Pagination,
  Table,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Eye, Pencil } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import PendientRequest from "./PendientRequest";
import { useStyles } from "./styles";
import { ThemeMode } from "../../../../Enums/themeMode";

type IClients = {
  id: string;
  companyName: string;
  responsible: string;
  email: string;
  phone: string;
  toDeleteDate?: string;
  servicesRequested: number;
  usersRequested: number;
};

const ClientsTable = ({ elements }: { elements: IClients[] }) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  return (
    <>
      <Table highlightOnHover className={classes.table}>
        <thead className={classes.tableHeader}>
          <tr>
            <th>
              <Text className={classes.headText}>{t("companyLabel")}</Text>
            </th>
            <th>
              <Text className={classes.headText}>{t("responsable")}</Text>
            </th>
            <th>
              <Text className={classes.headText}>{t("emailLabel")}</Text>
            </th>
            <th>
              <Text className={classes.headText}>{t("phoneLabel")}</Text>
            </th>
            <th>
              <Text className={classes.headText}>
                {t("awaitingRequestsLabel")}
              </Text>
            </th>
            <th>
              <Text className={classes.headText}>{t("actionsLabel")}</Text>
            </th>
          </tr>
        </thead>
        {elements.length > 0 ? (
          <tbody className={classes.bodyContainer}>
            {elements.map((element: any) => (
              <tr
                key={element.id}
                style={{
                  backgroundColor: element?.isActive
                    ? "transparent"
                    : "#6D6D6D33",
                }}
              >
                <td>
                  <Text
                    style={{
                      color: !element?.isActive
                        ? "#6D6D6D33"
                        : theme.colorScheme === ThemeMode.DARK
                        ? theme.colors.gray[1]
                        : theme.colors.light[1],
                    }}
                  >
                    {element.companyName}
                  </Text>
                </td>
                <td>
                  <Text
                    style={{
                      color: !element?.isActive
                        ? "#6D6D6D33"
                        : theme.colorScheme === ThemeMode.DARK
                        ? theme.colors.gray[1]
                        : theme.colors.light[1],
                    }}
                  >
                    {element.responsible}
                  </Text>
                </td>
                <td>
                  <Text
                    style={{
                      color: !element?.isActive
                        ? "#6D6D6D33"
                        : theme.colorScheme === ThemeMode.DARK
                        ? theme.colors.gray[1]
                        : theme.colors.light[1],
                    }}
                  >
                    {element.email}
                  </Text>
                </td>
                <td>
                  <Text
                    style={{
                      color: !element?.isActive
                        ? "#6D6D6D33"
                        : theme.colorScheme === ThemeMode.DARK
                        ? theme.colors.gray[1]
                        : theme.colors.light[1],
                    }}
                  >
                    {element.phone}
                  </Text>
                </td>
                <td>
                  <Box className={classes.requestMainContainer}>
                    <PendientRequest
                      tooltipValue="servicesPendient"
                      quantity={
                        element.toDeleteDate !== ""
                          ? 0
                          : element.servicesRequested
                      }
                      type="services"
                      itemId={element.id}
                      emptyPendientMessage="noServicesPendient"
                    />
                    <PendientRequest
                      tooltipValue="usersPendient"
                      quantity={
                        element.toDeleteDate !== "" ? 0 : element.usersRequested
                      }
                      type="users"
                      itemId={element.id}
                      emptyPendientMessage="noUsersPendient"
                    />
                  </Box>
                </td>
                <td>
                  <Box className={classes.actionsContainer}>
                    <ActionIcon
                      className={classes.iconActionContainer}
                      onClick={() => navigate(`cliente/${element.id}`)}
                    >
                      <Eye
                        size={15}
                        color={
                          theme.colorScheme === ThemeMode.DARK
                            ? theme.colors.dark[0]
                            : theme.colors.light[0]
                        }
                      />
                    </ActionIcon>
                    <ActionIcon
                      className={classes.iconActionContainer}
                      onClick={() =>
                        navigate(`cliente/editar-cliente/${element.id}`)
                      }
                    >
                      <Pencil
                        size={15}
                        color={
                          theme.colorScheme === ThemeMode.DARK
                            ? theme.colors.dark[0]
                            : theme.colors.light[0]
                        }
                      />
                    </ActionIcon>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody className={classes.bodyContainer}>
            <tr>
              <td>
                <Text className={classes.emptyResultsMessage}>
                  {t("emptyResults")}
                </Text>
              </td>
            </tr>
          </tbody>
        )}
      </Table>
    </>
  );
};

export default ClientsTable;

import { Box, Button, Select, Text, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { rolesList } from "../../Views/utils";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./styles";
import { useViewportSize } from "@mantine/hooks";
import { ThemeMode } from "../../../../Enums/themeMode";

type ICreateUserForm = {
  isSubmitting: boolean;
  form: any;
};

const CreateUserForm = ({ isSubmitting, form }: ICreateUserForm) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { width } = useViewportSize();
  const inputWidth = width > 700 ? 530 : 290;
  const { classes } = useStyles();

  return (
    <>
      <Box className={classes.boxItemsContainer}>
        <TextInput
          size="sm"
          label={t("nameLabel")}
          disabled={isSubmitting}
          withAsterisk
          required
          inputWrapperOrder={["label", "input", "description", "error"]}
          {...form.getInputProps("firstName")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isSubmitting
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isSubmitting
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
        <TextInput
          size="sm"
          label={t("lastnameLabel")}
          disabled={isSubmitting}
          withAsterisk
          required
          inputWrapperOrder={["label", "input", "description", "error"]}
          {...form.getInputProps("lastName")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isSubmitting
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isSubmitting
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
      </Box>
      <Box className={classes.boxItemsContainer}>
        <TextInput
          size="sm"
          label={t("phoneLabel")}
          disabled={isSubmitting}
          inputWrapperOrder={["label", "input", "description", "error"]}
          {...form.getInputProps("phone")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isSubmitting
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isSubmitting
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
        <TextInput
          size="sm"
          withAsterisk
          label={t("emailLabel")}
          disabled={isSubmitting}
          inputWrapperOrder={["label", "input", "description", "error"]}
          {...form.getInputProps("email")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isSubmitting
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isSubmitting
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
      </Box>
      <Box className={classes.boxItemsContainer}>
        <TextInput
          size="sm"
          label={t("position")}
          disabled={isSubmitting}
          inputWrapperOrder={["label", "input", "description", "error"]}
          {...form.getInputProps("position")}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isSubmitting
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isSubmitting
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
        <Box
          style={{
            width: inputWidth,
          }}
          className={classes.boxSelectContainer}
        >
          <Select
            disabled={isSubmitting}
            data={rolesList}
            label={"Rol"}
            required
            errorMessage={form?.errors?.rol || false}
            {...form.getInputProps("rol")}
            styles={(theme) => ({
              label: {
                color:
                  theme.colorScheme === ThemeMode.DARK
                    ? theme.colors.dark[1]
                    : theme.colors.light[1],
              },
              input: {
                width: inputWidth,
                color: theme.colorScheme === ThemeMode.DARK ? "#FFF" : "#000",
                border: isSubmitting
                  ? `0.5px solid ${theme.colors.dark[4]}`
                  : theme.colorScheme === ThemeMode.DARK
                  ? `1px solid ${theme.colors.dark[3]}`
                  : "0.5px solid",
                backgroundColor:
                  theme.colorScheme === ThemeMode.DARK
                    ? "transparent"
                    : theme.colors.light[0],
                "&:focus-within": {
                  borderColor: theme.colors.green[7],
                },
              },
            })}
          />
        </Box>
      </Box>
      <Box className={classes.buttonsContainer}>
        <Button
          disabled={isSubmitting}
          className={classes.buttonAdd}
          type={"submit"}
        >
          <Text>{isSubmitting ? t("loading") : t("add")}</Text>
        </Button>
        <Button
          variant="outline"
          disabled={isSubmitting}
          className={classes.buttonCancel}
          onClick={() => navigate(-1)}
        >
          <Text>{t("cancelOption")}</Text>
        </Button>
      </Box>
    </>
  );
};

export default CreateUserForm;

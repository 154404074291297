import { createStyles } from "@mantine/core";
import { ThemeMode } from "../../../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  mainContainer: {
    maxWidth: "none",
    display: "flex",
    flexDirection: "column",
  },
  boxTableContainer: {
    marginTop: "2rem",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowX: "scroll",
    position: "relative",
    flexWrap: "wrap",
  },
  input: {
    width: "15rem",
    float: "right",
    alignSelf: "self-end",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    padding: 0,
    width: "100%",
  },
  boxSearchbarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    marginTop: "1.5rem",
    marginBottom: "0.5rem",
  },
  tabsContainer: {
    overflowX: "scroll",
    position: "relative",
    flexWrap: "wrap",
  },
  notification: {
    position: 'absolute',
    right: 0,
    top: '3.5rem'
  },
  tabText: {
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
  }
}));

import { createStyles } from "@mantine/core";
import { CustomFonts } from "../../../../Components/Fonts";
import { ThemeMode } from "../../../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    border: "0.3px solid",
    borderColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[3]
        : theme.colors.light[3],
  },
  textLabel: {
    ...CustomFonts.Small(),
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[3],
    fontWeight: "bold",
  },
  rowValueText: {
    ...CustomFonts.Small(),
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
    fontWeight: "normal",
  },
}));

import {
  Box,
  Container,
  Divider,
  LoadingOverlay,
  Notification,
  Switch,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomHeaderBreadcrumbs from "../../../../Components/CustomHeaderBreadcrumbs";
import CustomModal from "../../../../Components/CustomModals/Modal/Modal";
import RestorePasswordModal from "../../../../Components/CustomModals/RestorePasswordModal";
import { getClientDataById } from "../../Controllers/clientsController";
import {
  deleteUserById,
  getUserDataById,
  updateUser,
  updateUserPassword,
  updateUserStatus,
} from "../../Controllers/usersController";
import { useStyles } from "./styles";
import Countdown from "../../../../Components/Countdown";
import OutletContainer from "../../../../Components/OutletContainer";
import EditUserForm from "../../Components/EditUserForm";
import { ThemeMode } from "../../../../Enums/themeMode";

const EditUser = () => {
  const { clientId, userId }: any = useParams();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const [isLoading, setIsLoading] = useState<any>(true);
  const [isUserEnabled, setIsUserEnabled] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [clientData, setClientData] = useState("");
  const [isModalVisible, setIsModalVisible] = useState({
    isVisible: false,
    success: false,
  });
  const [userDeletedVisible, setUserDeletedVisible] = useState({
    isVisible: false,
    success: false,
  });
  const [isRestorePasswordModalVisible, setIsRestorePasswordModalVisible] =
    useState(false);
  const [isNotificationErrorVisible, setNotificationErrorVisible] =
    useState(false);

  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      position: "",
      rol: "",
      toDeleteDate: "",
    },
    validate: {
      rol: (value) => (value.length === 0 ? t("required_field") : null),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : t("invalid_email")),
    },
  });

  useEffect(() => {
    getData();
    getClientData();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    const { data, error } = await getUserDataById(userId);
    if (!error) {
      form.setValues(data);
      setIsUserEnabled(data.enabled);
    } else {
      setNotificationErrorVisible(true);
    }
    setIsLoading(false);
  };

  const getClientData = async () => {
    const { data } = await getClientDataById(clientId);
    setClientData(data.name);
    getData();
    setIsLoading(false);
  };

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);
    const { data, error } = await updateUser(userId, values);
    setIsModalVisible({
      isVisible: true,
      success: error === null,
    });
    setIsSubmitting(false);
  };

  const updateStatus = async (status: boolean) => {
    setIsSubmitting(true);
    const { error } = await updateUserStatus(userId, status);
    setIsSubmitting(false);
    setIsModalVisible({
      isVisible: true,
      success: error === null,
    });
    if (error === null) {
      getData();
      getClientData();
    }
  };

  const onChangePassword = async (option: string, password?: string) => {
    const { error } = await updateUserPassword(option, userId, password);
    setIsRestorePasswordModalVisible(false);
    setIsModalVisible({
      isVisible: true,
      success: error === null,
    });
  };

  const onDeleteClient = async () => {
    setIsDeleteModalVisible(false);
    const restoreOrDelete = form.values.toDeleteDate !== "";
    const { error } = await deleteUserById(userId, restoreOrDelete);
    if (error === null) {
      getData();
      getClientData();
    }
  };

  return (
    <>
      {!isLoading && form.values?.toDeleteDate && (
        <Countdown type={"usuario"} date={form.values?.toDeleteDate} />
      )}
      <CustomHeaderBreadcrumbs
        title={clientData ? `${clientData} - ${t("editUser")}` : t("editUser")}
        description={"userCrud"}
      />
      <OutletContainer>
        <LoadingOverlay
          visible={isSubmitting}
          loaderProps={{ color: "green" }}
          overlayColor={
            theme.colorScheme === ThemeMode.DARK
              ? theme.colors.dark[0]
              : theme.colors.dark[1]
          }
        />
        <Container className={classes.mainContainer}>
          <Box className={classes.topContainer}>
            <Text className={classes.userInfoTitle}>{t("userInfoTitle")}</Text>
            <Box className={classes.switchUserContainer}>
              {isSubmitting ? (
                <Text className={classes.userStatusText}>
                  {t("processing")}
                </Text>
              ):(
                <Text className={classes.userStatusText}>
                  {isUserEnabled ? t("userEnabled") : t("userDisabled")}
                </Text>
              )}
              <Switch
                checked={isUserEnabled}
                onChange={() => updateStatus(!isUserEnabled)}
                color={"green"}
              />
            </Box>
          </Box>
          <Divider className={classes.divider} />
          <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <EditUserForm
              form={form}
              isSubmitting={isSubmitting}
              setIsRestorePasswordModalVisible={
                setIsRestorePasswordModalVisible
              }
              setIsDeleteModalVisible={setIsDeleteModalVisible}
            />
          </form>
        </Container>
      </OutletContainer>
      {isModalVisible.isVisible && (
        <CustomModal
          title={
            isModalVisible.success ? t("userEdited") : t("userEditedError")
          }
          isModalVisible={isModalVisible.isVisible}
          success={isModalVisible.success}
          onCloseModal={() => {
            setIsModalVisible((prev) => ({
              ...prev,
              isVisible: false,
            }));
          }}
        />
      )}
      <RestorePasswordModal
        isVisible={isRestorePasswordModalVisible}
        onCloseModal={() => setIsRestorePasswordModalVisible(false)}
        username={`${form.values.firstName} ${form.values.lastName}`}
        onSubmit={onChangePassword}
      />
      {isDeleteModalVisible && (
        <CustomModal
          isModalVisible={isDeleteModalVisible}
          onCloseModal={() => setIsDeleteModalVisible(false)}
          description={
            form.values.toDeleteDate !== "" ? "" : "deleteClientDescription"
          }
          success={false}
          buttons
          title={
            form.values.toDeleteDate !== ""
              ? "restoreUserTitle"
              : "deleteUserTitle"
          }
          buttonCancelText={"cancelOption"}
          buttonCancelFunction={() => setIsDeleteModalVisible(false)}
          buttonConfirmText={
            form.values.toDeleteDate !== "" ? "restoreOption" : "deleteOption"
          }
          buttonConfirmFunction={onDeleteClient}
        />
      )}
      {userDeletedVisible.isVisible && (
        <CustomModal
          isModalVisible={userDeletedVisible.isVisible}
          onCloseModal={() =>
            setUserDeletedVisible({
              isVisible: false,
              success: false,
            })
          }
          success={userDeletedVisible.success}
          title={"userDeletedMessage"}
        />
      )}
      {isNotificationErrorVisible && (
        <Notification
          className={classes.notification}
          color="red"
          onClose={() => setNotificationErrorVisible(false)}
        >
          <Text>{t("generalError")}</Text>
        </Notification>
      )}
    </>
  );
};

export default EditUser;

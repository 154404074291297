import { createStyles } from "@mantine/core";
import { ThemeMode } from "../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  outletContainer: {
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[0]
        : theme.colors.light[0],
    // position: "absolute",
    marginLeft: "1rem",
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 5,
    paddingLeft: 24,
    paddingRight: 24,
    marginTop: "-2%",
    boxShadow:
      theme.colorScheme === ThemeMode.DARK
        ? "none"
        : "2px 2px 2px 2px #DEDEDE57",
    width: "98%",
    // overflowY: 'scroll',
    // height: '75vh',
    [theme.fn.smallerThan('sm')]: {
      width: "95%",
    }
  },
}));

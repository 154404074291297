import {
  Box,
  Container,
  LoadingOverlay,
  Notification,
  Pagination,
  Tabs,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Search } from "tabler-icons-react";
import CustomHeaderBreadcrumbs from "../../../Components/CustomHeaderBreadcrumbs";
import ClientsTable from "../Components/Table";
import { getClientsData } from "../Controllers/clientsController";
import { useStyles } from "./styles";
import OutletContainer from "../../../Components/OutletContainer";
import { ThemeMode } from "../../../Enums/themeMode";

const Clients = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [clientsFiltered, setClientsFiltered] = useState<any>([]);
  const [timer, setTimer] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState("activeClients");
  const [isNotificationErrorVisible, setNotificationErrorVisible] =
    useState(false);
  const [actualPage, setActualPage] = useState(1);
  const [pages, setPages] = useState<number>(0);

  useEffect(() => {
    getClientsList(actualPage);
  }, [activeTab]);

  const getClientsList = async (actualPage: number, keyword?: string) => {
    setActualPage(actualPage);
    setIsLoading(true);
    const clientEnabled = activeTab === "activeClients";
    const { data, error, totalPages } = await getClientsData(
      actualPage,
      10,
      clientEnabled,
      keyword
    );
    if (error !== null) {
      setNotificationErrorVisible(true);
    }
    setPages(totalPages || 0);
    const dataClientsActive = data.filter(
      (item: any) => item?.toDeleteDate === ""
    );
    const dataClientsDeleted = data.filter(
      (item: any) => item?.toDeleteDate !== ""
    );
    setClientsFiltered({
      clientsActive: dataClientsActive,
      clientsDeleted: dataClientsDeleted,
    });
    setIsLoading(false);
  };

  const inputChanged = (value: string) => {
    clearTimeout(timer);
    const newTimer: any = setTimeout(() => {
      getClientsList(1, value);
    }, 500);
    setTimer(newTimer);
  };

  return (
    <Box
      style={{
        overflowY: "scroll",
        overflow: "hidden",
      }}
    >
      <CustomHeaderBreadcrumbs
        title={"clients"}
        description={"clientsDescription"}
        showButton
        buttonTitle={"createClient"}
        buttonAction={() => navigate("/clientes/crear-clientes")}
      />
      <OutletContainer>
        <Container className={classes.mainContainer}>
          <Box className={classes.input}>
            <TextInput
              placeholder={t("searchPlaceholder") || ""}
              icon={<Search size={"1rem"} />}
              onChange={(e: any) => inputChanged(e.target.value)}
              size="sm"
              inputWrapperOrder={["label", "input", "description", "error"]}
              styles={(theme) => ({
                label: {
                  color:
                    theme.colorScheme === ThemeMode.DARK
                      ? theme.colors.dark[1]
                      : theme.colors.light[1],
                },
                input: {
                  border: `0.5px solid ${theme.colors.dark[4]}`,
                  backgroundColor:
                    theme.colorScheme === ThemeMode.DARK
                      ? "transparent"
                      : theme.colors.light[0],
                  marginRight: "2rem",
                  "&:focus-within": {
                    borderColor: theme.colors.green[7],
                  },
                  color: "#000!important",
                },
              })}
            />
          </Box>
          <Box className={classes.boxTableContainer}>
            <LoadingOverlay
              visible={isLoading}
              loaderProps={{ color: "green" }}
              overlayColor={
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[0]
                  : theme.colors.dark[1]
              }
            />
            <Tabs defaultValue="activeClients" color={"green"}>
              <Tabs.List>
                <Tabs.Tab
                  value="activeClients"
                  onClick={() => {
                    setActiveTab("activeClients");
                  }}
                >
                  <Text className={classes.tabText}>{t("activeClients")}</Text>
                </Tabs.Tab>
                <Tabs.Tab
                  value="clientsDeleted"
                  onClick={() => {
                    setActiveTab("clientsDeleted");
                  }}
                >
                  <Text className={classes.tabText}>{t("clientsDeleted")}</Text>
                </Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value={"activeClients"}>
                <ClientsTable elements={clientsFiltered?.clientsActive || []} />
                {clientsFiltered?.clientsActive?.length > 0 && (
                  <Pagination
                    total={pages}
                    color="green"
                    onChange={(e) => getClientsList(e)}
                  />
                )}
              </Tabs.Panel>
              <Tabs.Panel value={"clientsDeleted"}>
                <ClientsTable
                  elements={clientsFiltered?.clientsDeleted || []}
                />
                {clientsFiltered?.clientsDeleted?.length > 0 && (
                  <Pagination
                    total={pages}
                    color="green"
                    onChange={(e) => getClientsList(e)}
                  />
                )}
              </Tabs.Panel>
            </Tabs>
          </Box>
        </Container>
      </OutletContainer>
      {isNotificationErrorVisible && (
        <Notification
          className={classes.notification}
          color="red"
          onClose={() => setNotificationErrorVisible(false)}
        >
          <Text>{t("generalError")}</Text>
        </Notification>
      )}
    </Box>
  );
};

export default Clients;

import { useKeycloak } from "@react-keycloak/web";
import { decodeJwt } from "jose";
import { Roles } from "../Navigation/Protected";

export const useGetRoleName = () => {
  const {
    keycloak: { token },
  } = useKeycloak();
  const tokenDecoded: any = token ? decodeJwt(token) : null;
  const roleFounded = tokenDecoded?.realm_access.roles.find(
    (e: any) => e === Roles.ADMIN || e === Roles.SUPERADMIN
  );
  return roleFounded;
};

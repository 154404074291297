import {
  Box,
  Button,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { Moon, Sun } from "tabler-icons-react";
import { useStyles } from "./styles";
import { ThemeMode } from "../../Enums/themeMode";

const SwitchThemeMobile = () => {
  const { classes } = useStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === ThemeMode.DARK;
  const theme = useMantineTheme();

  return (
    <Button
      className={classes.mainContainer}
      variant="subtle"
      onClick={() => {
        localStorage.setItem(
          "color-scheme",
          colorScheme === ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK
        );
        toggleColorScheme();
      }}
    >
      <Box className={classes.icon}>
        {dark ? (
          <Sun color={theme.colors.dark[0]} size={20} />
        ) : (
          <Moon color={theme.colors.light[0]} size={20} />
        )}
      </Box>
    </Button>
  );
};

export default SwitchThemeMobile;

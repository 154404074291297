import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  boxMainContainer: {
    backgroundColor: theme.colors.red[9],
    display: "flex",
    justifyContent: "center",
    paddingTop: "0.8rem",
    paddingBottom: "0.8rem",
  },
  timerText: {
    color: theme.colors.light[0],
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginRight: '0.5rem'
  }
}));

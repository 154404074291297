import { createStyles } from "@mantine/core";
import { ThemeMode } from "../../../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  table: {
    marginBottom: "3rem",
  },
  tableHeader: {
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.gray[4]
        : theme.colors.gray[3],
  },
  headText: {
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
  },
  requestBoxContainer: {
    border: "solid 1px",
    borderColor: "#FFDB5C",
    width: 56,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 3,
    backgroundColor: "#FFFEF1",
  },
  quantityText: {
    marginLeft: 5,
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  iconTrashContainer: {
    backgroundColor: "#E63324",
    borderRadius: 50,
    marginRight: 10,
    alignSelf: "center",
    alignItems: "center",
  },
  actionLink: {
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "left",
    background: "transparent",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
    "&:hover": {
      background: "transparent",
    },
  },
  emptyResultsMessage: {
    textAlign: "center",
    marginTop: "1rem",
    position: "absolute",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.gray[1]
        : theme.colors.light[1],
    width: "100%",
  },
  bodyContainer: {
    justifyContent: "center",
  },
  tableText: {
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.gray[1]
        : theme.colors.light[1],
  },
  roles: {
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[0]
        : theme.colors.light[1],
    textAlign: "center",
    borderRadius: 12,
  },
}));

import {
  Container,
  LoadingOverlay,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomHeaderBreadcrumbs from "../../../../Components/CustomHeaderBreadcrumbs";
import CustomModal from "../../../../Components/CustomModals/Modal/Modal";
import { useTranslation } from "react-i18next";
import {
  createClient,
  deleteClientById,
  getClientDataById,
  updateClientById,
} from "../../Controllers/clientsController";
import { useStyles } from "./styles";
import OutletContainer from "../../../../Components/OutletContainer";
import CreateClientForm from "../../Components/CreateClientForm";
import Countdown from "../../../../Components/Countdown";
import { ThemeMode } from "../../../../Enums/themeMode";

const CreateClient = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const theme = useMantineTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [clientNewId, setClientNewId] = useState("");
  const [modalVisible, setIsModalVisible] = useState({
    isVisible: false,
    success: false,
    type: "create",
    message: "",
  });

  const form = useForm({
    initialValues: {
      name: "",
      responsible_name: "",
      responsible_lastname: "",
      phone: "",
      email: "",
      address: "",
      cif: "",
      toDeleteDate: "",
    },
    validate: {
      name: (value) => (value.length === 0 ? t("required_field") : null),
      email: (value) => {
        if (/^\S+@\S+$/.test(value)) return null;
        if (value.length === 0) return t("required_field");
        return t("invalid_email");
      },
    },
  });

  useEffect(() => {
    getClientById();
  }, []);

  const getClientById = async () => {
    setIsLoading(true);
    if (clientId) {
      const { data, error } = await getClientDataById(clientId);
      form.setValues(data);
    }
    setIsLoading(false);
  };

  const createOrUpdateClient = async (clientData: any) => {
    setIsLoading(true);
    if (clientId) {
      const { data, error } = await updateClientById(clientId, clientData);
      setIsModalVisible({
        isVisible: true,
        success: error === null,
        type: "edit",
        message:
          data !== null ? "clientUpdatedTitle" : "clientUpdatedTitleError",
      });
    } else {
      const { data, error } = await createClient(clientData);
      setIsModalVisible({
        isVisible: true,
        success: error === null,
        type: "create",
        message:
          error === null ? "clientCreatedTitle" : "clientCreatedTitleError",
      });
      if (error === null) {
        form.reset();
        setClientNewId(data.data.id);
      }
    }
    setIsLoading(false);
  };

  const onDeleteClient = async () => {
    setIsDeleteModalVisible(false);
    setIsLoading(true);
    const restoreOrDelete = form.values.toDeleteDate === "";
    const { data, error } = await deleteClientById(clientId, restoreOrDelete);
    getClientById();
    setIsLoading(false);
  };

  const onCloseModal = () => {
    if (!clientId && clientNewId !== "") {
      navigate(`/cliente/${clientNewId}`);
    } else {
      navigate(-1);
    }
    setIsModalVisible((prev) => ({
      ...prev,
      isVisible: false,
    }));
  };

  return (
    <>
      {!isLoading && form.values?.toDeleteDate && (
        <Countdown type={"cliente"} date={form.values?.toDeleteDate} />
      )}
      <CustomHeaderBreadcrumbs
        title={clientId ? "editClient" : "createClient"}
        description={"clientsDescription"}
      />
      <OutletContainer>
        <LoadingOverlay
          visible={isLoading}
          loaderProps={{ color: "green" }}
          overlayColor={
            theme.colorScheme === ThemeMode.DARK
              ? theme.colors.dark[0]
              : theme.colors.dark[1]
          }
        />
        <Container className={classes.mainContainer}>
          <Text className={classes.createClientTitle}>{t("userInfo")}</Text>
          <form
            onSubmit={form.onSubmit((values) => createOrUpdateClient(values))}
          >
            <CreateClientForm
              clientId={clientId}
              isLoading={isLoading}
              form={form}
              setIsDeleteModalVisible={setIsDeleteModalVisible}
            />
          </form>
        </Container>
      </OutletContainer>
      {modalVisible.isVisible && (
        <CustomModal
          isModalVisible={modalVisible.isVisible}
          onCloseModal={onCloseModal}
          title={modalVisible.message}
          success={modalVisible.success}
        />
      )}
      <CustomModal
        isModalVisible={isDeleteModalVisible}
        onCloseModal={() => setIsDeleteModalVisible(false)}
        description={
          form.values.toDeleteDate !== "" ? `` : "warningClientDescription"
        }
        success={false}
        title={
          form.values.toDeleteDate !== ""
            ? "restoreClientTitle"
            : "deleteClientTitle"
        }
        buttons
        buttonCancelText={"cancelOption"}
        buttonCancelFunction={() => setIsDeleteModalVisible(false)}
        buttonConfirmText={
          form.values.toDeleteDate !== "" ? "restoreOption" : "deleteOption"
        }
        buttonConfirmFunction={onDeleteClient}
      />
    </>
  );
};

export default CreateClient;

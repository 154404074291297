import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  boxItemsContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    display: 'flex',
    [theme.fn.smallerThan('sm')]: {
      display: 'grid',
    }
  },
  buttonAdd: {
    backgroundColor: theme.colors.dark[2],
    width: 130,
    marginRight: "2rem",
    ":hover": {
      backgroundColor: theme.colors.dark[2],
    },
  },
  buttonCancel: {
    borderColor: theme.colors.dark[2],
    color: theme.colors.dark[2],
    width: 130,
    marginRight: "2rem",
    ":hover": {
      borderColor: theme.colors.dark[2],
    },
  },
  buttonsContainer: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  boxSelectContainer: {
   marginRight: '1.7rem'
  },
}));

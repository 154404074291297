import { Box, Button, Modal, Text } from "@mantine/core";
import { Check, ExclamationMark } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

type ICustomModal = {
  isModalVisible: boolean;
  onCloseModal: () => void;
  title: string;
  description?: string;
  success?: boolean;
  buttonCancelText?: string;
  buttonCancelFunction?: () => void;
  buttonConfirmFunction?: () => void;
  buttonConfirmText?: string;
  disabled?: boolean;
  buttons?: boolean;
};

const checkIcons = {
  success: <Check color={"green"} size={40} />,
  error: <ExclamationMark color={"red"} size={40} />,
};

const CustomModal = (props: ICustomModal) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const {
    isModalVisible,
    onCloseModal,
    title,
    description,
    success,
    buttonCancelText,
    buttonCancelFunction,
    buttonConfirmFunction,
    buttonConfirmText,
    disabled = false,
    buttons = false,
  } = props;

  return (
    <Modal
      opened={isModalVisible}
      onClose={onCloseModal}
      centered
      zIndex={99}
    >
      <Box className={classes.mainContainer}>
        <Box
          className={classes.boxIconContainer}
          style={{
            borderColor: success ? "green" : "red",
          }}
        >
          {checkIcons[success ? "success" : "error"]}
        </Box>
        <Text className={classes.title}>{t(`${title}`)}</Text>
        {description && (
          <Text className={classes.description}>{t(`${description}`)}</Text>
        )}
      </Box>
      {buttons && (
        <Box className={classes.buttonsContainer}>
          <Button
            onClick={buttonCancelFunction}
            variant="outline"
            color={"green"}
            disabled={disabled}
          >
            <Text>{t(`${!disabled ? buttonCancelText : "loading"}`)}</Text>
          </Button>
          <Button
            onClick={buttonConfirmFunction}
            variant="filled"
            color={"green"}
            disabled={disabled}
          >
            <Text>{t(`${buttonConfirmText}`)}</Text>
          </Button>
        </Box>
      )}
    </Modal>
  );
};

export default CustomModal;

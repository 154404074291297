import apiUser from "../../../Api/users";
import { IResponse } from "../../../Types/response";
const {
  create,
  deleteOne,
  getOne,
  update,
  updateStatus,
  resetPasswordDefault,
  resetPassword,
} = apiUser;

type IUser = {
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  position: string;
  rol: string;
  enabled: boolean;
  clientId: number;
};

export const createUser = async (values: IUser, clientId: string) => {
  const response: IResponse = { data: null, error: null };
  try {
    const objToSubmit = {
      lastName: values.lastName,
      firstName: values.firstName,
      email: values.email,
      phone: values?.phone,
      position: values?.position,
      role: values?.rol,
      enabled: true,
      clientId: parseInt(clientId),
    };
    response.data = await create(objToSubmit);
  } catch (err) {
    response.error = err;
  }
  return response;
};

export const deleteUserById = async (
  userId: string,
  deleteOrRestore: boolean
) => {
  const response: IResponse = { data: null, error: null };
  try {
    const { data } = await deleteOne(userId, deleteOrRestore);
    response.data = data === "Done";
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const getUserDataById = async (clientId: string) => {
  const response: IResponse = { data: null, error: null };
  try {
    const { data } = await getOne(clientId);
    response.data = {
      firstName: data?.firstName ?? "",
      lastName: data?.lastName ?? "",
      email: data?.email ?? "",
      phone: data?.phone ?? "",
      position: data?.position ?? "",
      rol: data?.role ?? "",
      enabled: data?.enabled ?? "",
      toDeleteDate: data?.toDeleteDate ?? "",
    };
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const updateUser = async (userId: string, clientData: any) => {
  const response: IResponse = { data: null, error: null };
  try {
    const objToSubmit = {
      lastName: clientData.lastName,
      firstName: clientData.firstName,
      email: clientData.email,
      phone: clientData?.phone,
      position: clientData?.position,
      role: clientData?.rol,
    };
    const { data } = await update(userId, objToSubmit);
    response.data = data;
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const updateUserStatus = async (userId: string, status: boolean) => {
  const response: IResponse = { data: null, error: null };
  try {
    const { data } = await updateStatus(userId, status);
    response.data = data;
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const updateUserPassword = async (
  option: string,
  userId: string,
  password?: string
) => {
  const response: IResponse = { data: null, error: null };
  try {
    if (option === "generate") {
      const { data } = await resetPasswordDefault(userId);
      response.data = data;
    } else {
      const { data } = await resetPassword(userId, password);
      response.data = data;
    }
  } catch (_) {
    response.error = false;
  }
  return response;
};

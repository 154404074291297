import apiClients from "../../../Api/clients";
import apiUser from "../../../Api/users";
import apiServices from "../../../Api/services";
import { IResponse } from "../../../Types/response";
import { format } from "date-fns";
const {
  getById,
  deleteById,
  update,
  create,
  getByIdPaginated,
  toggleStatus,
  getClientServices,
  getAllPaginated,
} = apiClients;
const { updateActivation } = apiUser;
const {
  getAllServices,
  getAllServicesHistory,
  getPendingServicesPaginated,
  updateServiceActivation,
} = apiServices;

export const getClientsData = async (
  page: number,
  limit: number,
  clientEnabled: boolean,
  keyword?: string
) => {
  const response: IResponse = {
    data: null,
    error: null,
    totalPages: 0,
  };
  try {
    const { data } = await getAllPaginated(page, limit, clientEnabled, keyword);
    if (data) {
      response.data = data?.items.map((item: any) => ({
        id: item.id,
        companyName: item?.name || "-",
        responsible:
          item?.responsible_name === null && item?.responsible_lastname === null
            ? "-"
            : `${item?.responsible_name} ${item?.responsible_lastname}`,
        email: item?.email !== null ? item?.email : "-",
        phone: item?.phone !== null ? item?.phone : "-",
        request: 0,
        isActive: item?.isActive,
        toDeleteDate: item?.toDeleteDate || "",
        usersRequested: item?.usersRequest || 0,
        servicesRequested: item?.servicesRequest || 0,
      }));
      response.totalPages = data.meta.totalPages;
    } else {
      response.data = [];
      response.totalPages = data.meta.totalPages;
    }
  } catch (_) {
    response.error = false;
    response.totalPages = 0;
  }
  return response;
};

export const deleteClientById = async (
  id: string | undefined,
  deleteOrRestore: boolean
) => {
  const response: IResponse = {
    data: null,
    error: null,
  };
  try {
    const obj = {
      toDeleteDate: deleteOrRestore,
    };
    const { data } = await deleteById(id, obj);
    response.data = data.statusText === "OK" || data.statusText === "Done";
  } catch (err) {
    response.error = false;
  }
  return response;
};

export const createClient = async (clientData: any) => {
  const response: IResponse = {
    data: null,
    error: null,
  };
  try {
    const obj = {
      name: clientData.name,
      responsible_name: clientData.responsible_name,
      responsible_lastname: clientData.responsible_lastname,
      phone: clientData.phone,
      email: clientData.email,
      address: clientData.address,
      cif: clientData.cif === "" ? null : clientData.cif,
    };
    response.data = await create(obj);
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const getClientDataById = async (clientId: string) => {
  const response: IResponse = {
    data: null,
    error: null,
  };

  try {
    const { data } = await getById(clientId);
    response.data = {
      id: data.id,
      name: data?.name || "",
      responsible_name: data?.responsible_name || "",
      responsible_lastname: data?.responsible_lastname || "",
      email: data?.email || "",
      phone: data?.phone || "",
      street: data?.address || "",
      cif: data?.cif || "",
      request: 0,
      isActive: data?.isActive,
      clientUsers: data?.users || [],
      toDeleteDate: data?.toDeleteDate || "",
    };
  } catch (err) {
    response.error = err;
  }
  return response;
};

export const getClientDataByIdPaginated = async (
  clientId: string,
  limit: number,
  page: number,
  isActive: boolean,
  keyword?: string
) => {
  const response: IResponse = {
    data: null,
    error: null,
    totalPages: 0,
  };

  try {
    const { data } = await getByIdPaginated(
      clientId,
      limit,
      page,
      isActive,
      keyword
    );
    response.data = {
      id: data.id,
      name: data?.name || "",
      responsible_name: data?.responsible_name || "",
      responsible_lastname: data?.responsible_lastname || "",
      email: data?.email || "",
      phone: data?.phone || "",
      street: data?.address || "",
      cif: data?.cif || "",
      request: 0,
      isActive: data?.isActive,
      clientUsers: data?.users || [],
      toDeleteDate: data?.toDeleteDate || "",
    };
    response.totalPages = data.users.meta.totalPages;
  } catch (err) {
    response.error = err;
    response.totalPages = 0;
  }
  return response;
};

export const updateClientById = async (id: string, clientData: any) => {
  const response: IResponse = {
    data: null,
    error: null,
  };
  try {
    const objToSubmit: any = {
      name: clientData?.name,
      responsible_name: clientData?.responsible_name,
      responsible_lastname: clientData?.responsible_lastname,
      email: clientData?.email,
      phone: clientData?.phone,
      address: clientData?.address,
      cif: clientData.cif === "" ? null : clientData.cif,
    };
    const { data } = await update(id, objToSubmit);
    response.data = data;
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const updateByStatus = async (id: string, status: any) => {
  const response: IResponse = {
    data: null,
    error: null,
  };
  try {
    const { data } = await toggleStatus(id, status);
    response.data = data;
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const activateOrDesactivate = async (
  id: string,
  enableOrDisable: boolean
) => {
  const response: IResponse = {
    data: null,
    error: null,
  };
  try {
    const response = await updateActivation(id, enableOrDisable);
    response.data = response;
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const getServicesByClientId = async (clientId: string) => {
  const response: IResponse = {
    data: null,
    error: null,
  };
  try {
    const { data } = await getClientServices(clientId);
    response.data = data.filter((element: any) => element.isActivated !== null);
  } catch (_) {
    response.error = false;
  }
  return response;
};

type IService = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  name: string;
  serviceType: string;
  residueType: string[];
  dockNumber: boolean;
  containerNumber: boolean;
  enableToEdit: boolean;
  isActive: boolean;
};

const validateStringify = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (_) {
    return false;
  }
};

export const getServices = async () => {
  const response: IResponse = {
    data: null,
    error: null,
  };
  try {
    const { data } = await getAllServices();
    response.data = data.map((item: IService) => {
      if (item.name !== "Otros servicios") {
        return {
          id: item.id,
          value: item.name,
          label: item.name,
        };
      } else {
        if (validateStringify(item.serviceType)) {
          const otherServices = validateStringify(item.serviceType);
          return {
            id: item.id,
            value: otherServices.join(", "),
            label: otherServices.join(", "),
            group: "Otros",
          };
        } else {
          return {
            id: item.id,
            value: item.serviceType,
            label: item.serviceType,
            group: "Otros",
          };
        }
      }
    });
  } catch (_) {
    response.error = false;
  }
  return response;
};

export const getHistory = async (
  clientId: string,
  limit: number,
  page: number,
  keyword?: string
) => {
  const response: IResponse = {
    data: null,
    error: null,
    totalPages: 0,
  };
  try {
    const { data } = await getAllServicesHistory(
      clientId,
      limit,
      page,
      keyword
    );
    response.data = data?.items?.map((item: any) => ({
      id: item.id,
      serviceName: item.service.name,
      requestedBy: item.userName,
      requestedAt: `${format(new Date(item.createdAt), "dd/MM/yyyy HH:mm")} hs`,
    }));
    response.totalPages = data.meta.totalPages;
  } catch (_) {
    response.error = false;
    response.totalPages = 0;
  }
  return response;
};

export const getPendingServicesList = async (
  clientId: string,
  limit: number,
  page: number,
  keyword?: string
) => {
  const response: IResponse = {
    data: null,
    error: null,
    totalPages: 0,
  };
  try {
    const { data } = await getPendingServicesPaginated(
      clientId,
      limit,
      page,
      keyword
    );
    const dataFiltered = data.items.filter((e: any) => !e.isActivated);
    response.data = dataFiltered.map((item: any) => ({
      id: item.id,
      serviceName: item.service.name,
      requestedBy: item.userName,
      requestedAt: `${format(new Date(item.createdAt), "dd/MM/yyyy HH:mm")} hs`,
    }));
    response.totalPages = data.meta.totalPages;
  } catch (_) {
    response.error = false;
    response.totalPages = 0;
  }
  return response;
};

export const toggleServiceRequest = async (
  id: string,
  enableOrDisable: boolean
) => {
  const response: IResponse = {
    data: null,
    error: null,
  };
  try {
    const response = await updateServiceActivation(id, enableOrDisable);
    response.data = response;
  } catch (_) {
    response.error = false;
  }
  return response;
};

import { ReactKeycloakProvider } from "@react-keycloak/web";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Loading from "./Components/Loading";
import keycloak from "./keycloak";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const onKeycloakEvent = (event: any) => {
  if (
    event === "onAuthError" ||
    event === "onAuthRefreshError" ||
    event === "onAuthLogout"
  ) {
    keycloak.login();
  }
};

const onKeycloakTokens = (tokens: any) => {
  if (tokens?.token) localStorage.setItem("KC_TOKEN", JSON.stringify(tokens));
  else keycloak.login();
};

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    onEvent={onKeycloakEvent}
    onTokens={onKeycloakTokens}
    LoadingComponent={<Loading />}
  >
    <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>
  </ReactKeycloakProvider>
);

import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from "@mantine/core";
import "./Translations";
import { useEffect, useState } from "react";
import { RoutesNavigation } from "./Navigation/routes";
import { ThemeMode } from "./Enums/themeMode";
import MainLayout from "./Components/MainLayout";

export const App = () => {
  const [colorScheme, setColorScheme] = useState<any>(
    localStorage.getItem("color-scheme") || ThemeMode.LIGHT
  );
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(
      value ||
        (colorScheme === ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK)
    );

  useEffect(() => {
    const scheme: any = localStorage.getItem("color-scheme");
    if (scheme === null) {
      setColorScheme(ThemeMode.LIGHT);
      localStorage.setItem("color-scheme", ThemeMode.LIGHT);
    } else {
      setColorScheme(scheme);
    }
  }, []);

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{
          colorScheme,
          colors: {
            dark: ["#16202B", "#FFFFFF", "#27A536", "#6D6D6D", "#1C2939"],
            light: ["#FFFFFF", "#000000", "#27A536", "#6D6D6D"],
          },
        }}
        withGlobalStyles
        withNormalizeCSS
      >
        <MainLayout>
          <RoutesNavigation />
        </MainLayout>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export default App;

import { createStyles } from "@mantine/core";
import { ThemeMode } from "../../../../Enums/themeMode";

export const useStyles = createStyles((theme) => ({
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconTrashContainer: {
    backgroundColor: "#E63324",
    borderRadius: 50,
    marginRight: 10,
    alignSelf: "center",
    alignItems: "center",
    "&: hover": {
      backgroundColor: "#E63324",
      opacity: 0.5,
    },
  },
  iconActionContainer: {
    backgroundColor: "#27A536",
    borderRadius: 50,
    marginRight: 10,
    alignSelf: "center",
    alignItems: "center",
    "&: hover": {
      backgroundColor: "#27A536",
      opacity: 0.5,
    },
  },
  serviceTypeText: {
    display: "inline",
    backgroundColor: theme.colors.dark[2],
    color: theme.colors.light[0],
    padding: "3px 5px",
    borderRadius: 5,
    marginRight: "0.5rem",
    marginBottom: 1,
    [theme.fn.smallerThan("sm")]: {
      display: "flex",
    },
  },
  normalText: {
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[1]
        : theme.colors.light[1],
  },
  tableResponsive: {
    [theme.fn.smallerThan("sm")]: {
      flexWrap: "wrap",
      overflow: "hidden",
      display: "flow",
      overflowX: "scroll",
      paddingBottom: "1rem",
    },
  },
  emptyResultsText: {
    textAlign: "center",
    marginTop: "1rem",
    color:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.gray[1]
        : theme.colors.light[1],
  },
  bodyContainer: {
    justifyContent: "center",
  },
  tableHeader: {
    backgroundColor:
      theme.colorScheme === ThemeMode.DARK
        ? theme.colors.dark[4]
        : theme.colors.gray[3],
  },
}));

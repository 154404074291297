import { createStyles } from "@mantine/core";
import { CustomFonts } from "../../Fonts";

export const useStyles = createStyles((theme) => ({
  mainContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  boxIconContainer: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    marginBottom: '1rem'
  },
  title: {
    ...CustomFonts.SubTitle(),
    fontWeight: "bold",
    textAlign: "center",
  },
  description: {
    ...CustomFonts.Small(),
    fontWeight: "normal",
    textAlign: "center",
  },
  modal: {
    zIndex: 99999,
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "1rem",
    paddingLeft: '5rem',
    paddingRight: '5rem',
    marginBottom: '2rem'
  },
  button: {
    width: '45%'
  }
}));

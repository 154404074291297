import { Box, Button, Switch, Text } from "@mantine/core";
import { Plus } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import ServicesItem from "./ServicesItem";
import { Protected } from "../../../../Navigation/Protected";
import { Roles } from "../../../../Navigation/Protected";

type IHiredServices = {
  onChangeCompanyStatus: (active: boolean) => void;
  isActive: boolean;
  disabled: boolean;
  isUpdating: boolean;
  setIsAddNewServiceVisible: any;
  services: any;
};

const HiredServices = ({
  onChangeCompanyStatus,
  isActive,
  disabled,
  isUpdating,
  setIsAddNewServiceVisible,
  services,
}: IHiredServices) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.cardContainer}>
      <Box>
        <Box className={classes.buttonAndTextContainer}>
          <Text className={classes.textLabel}>{t("hiredServicesLabel")}</Text>
          <Protected roles={[Roles.SUPERADMIN]} route={false}>
            <Button
              className={classes.button}
              variant="subtle"
              leftIcon={<Plus size={15} color={"green"} />}
              onClick={() => setIsAddNewServiceVisible(true)}
            >
              <Text className={classes.buttonText}>{t("manageServices")}</Text>
            </Button>
          </Protected>
        </Box>
        <Box className={classes.serviceList}>
          {<ServicesItem services={services} />}
        </Box>
      </Box>
      <Protected roles={[Roles.SUPERADMIN]} route={false}>
        <Box className={classes.buttonAndTextContainer}>
          {isUpdating ? (
            <Text className={classes.switchText}>{t("processing")}</Text>
          ) : (
            <Text className={classes.switchText}>
              {t("client")} {isActive ? t("enabled") : t("disabled")}
            </Text>
          )}
          <Switch
            checked={isActive}
            disabled={disabled}
            onChange={({ currentTarget: { value } }) =>
              onChangeCompanyStatus(!isActive)
            }
            color={"green"}
          />
        </Box>
      </Protected>
    </Box>
  );
};

export default HiredServices;

import {
  Box,
  LoadingOverlay,
  Notification,
  Pagination,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useEffect, useState } from "react";
import CustomHeaderBreadcrumbs from "../../../Components/CustomHeaderBreadcrumbs";
import { useStyles } from "./styles";
import { Check, Search } from "tabler-icons-react";
import ServicesTable from "../Components/ServicesTable";
import { getServicesData } from "../Controller/services";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OutletContainer from "../../../Components/OutletContainer";
import { ThemeMode } from "../../../Enums/themeMode";

const Services = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const [timer, setTimer] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [servicesData, setServicesData] = useState<any[]>([]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [serviceDeletedStatus, setServiceDeletedStatus] = useState({
    isVisible: false,
    success: false,
  });
  const [isNotificationErrorVisible, setNotificationErrorVisible] =
    useState(false);
  const [actualPage, setActualPage] = useState(1);
  const [pages, setPages] = useState<number>(0);

  const getData = async (actualPage: number, keyword?: string) => {
    setActualPage(actualPage);
    setIsLoading(true);
    const { data, error, totalPages } = await getServicesData(
      actualPage || 1,
      10,
      keyword || ""
    );
    if (error !== null) {
      setNotificationErrorVisible(true);
    }
    setPages(totalPages || 0);
    setServicesData(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(actualPage);
  }, []);

  useEffect(() => {
    if (serviceDeletedStatus.isVisible) {
      setTimeout(() => {
        setServiceDeletedStatus((prev) => ({
          ...prev,
          isVisible: false,
        }));
      }, 3000);
    }
  }, [serviceDeletedStatus]);

  const inputChanged = (value: string) => {
    clearTimeout(timer);
    const newTimer: any = setTimeout(() => {
      getData(1, value);
    }, 500);
    setTimer(newTimer);
  };

  return (
    <>
      <CustomHeaderBreadcrumbs
        title={`services`}
        description={"abmServices"}
        showButton
        buttonTitle="addServices"
        buttonAction={() => navigate("crear-servicio")}
      />
      <OutletContainer>
        <Box className={classes.inputContainer}>
          <TextInput
            placeholder={t("searchPlaceholder") || ""}
            icon={<Search size={"1rem"} />}
            onChange={(e: any) => inputChanged(e.target.value)}
            size="sm"
            inputWrapperOrder={["label", "input", "description", "error"]}
            styles={(theme) => ({
              label: {
                color:
                  theme.colorScheme === ThemeMode.DARK
                    ? theme.colors.dark[1]
                    : theme.colors.light[1],
              },
              input: {
                border: `0.5px solid ${theme.colors.dark[4]}`,
                backgroundColor:
                  theme.colorScheme === ThemeMode.DARK
                    ? "transparent"
                    : theme.colors.light[0],
                marginRight: "2rem",
                "&:focus-within": {
                  borderColor: theme.colors.green[7],
                },
                color: "#000!important",
              },
            })}
          />
        </Box>
        <ServicesTable
          servicesData={servicesData}
          setServiceDeletedStatus={setServiceDeletedStatus}
          setIsDeleteModalVisible={setIsDeleteModalVisible}
          isDeleteModalVisible={isDeleteModalVisible}
          getData={getData}
        />
        {servicesData?.length > 0 && (
          <Pagination
            total={pages}
            onChange={(e) => getData(e)}
            color="green"
          />
        )}
        <LoadingOverlay
          visible={isLoading}
          loaderProps={{ color: "green" }}
          overlayColor={
            theme.colorScheme === ThemeMode.DARK
              ? theme.colors.dark[0]
              : theme.colors.dark[1]
          }
        />
      </OutletContainer>
      {serviceDeletedStatus.isVisible && (
        <Notification
          color={serviceDeletedStatus.success ? "green" : "red"}
          onClose={() =>
            setServiceDeletedStatus((prev) => ({
              ...prev,
              isVisible: false,
            }))
          }
          icon={<Check />}
          title={
            serviceDeletedStatus.success
              ? t(`serviceDeleted`)
              : t(`serviceDeletedError`)
          }
          className={classes.notification}
        />
      )}
      {isNotificationErrorVisible && (
        <Notification
          className={classes.notification}
          color="red"
          onClose={() => setNotificationErrorVisible(false)}
        >
          <Text>{t("generalError")}</Text>
        </Notification>
      )}
    </>
  );
};

export default Services;

import { Box, Button, Table, Text, useMantineTheme } from "@mantine/core";
import { useState } from "react";
import CustomModal from "../../../../Components/CustomModals/Modal/Modal";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

type IService = {
  id: string;
  serviceName: string;
  requestedBy: string;
  requestedAt: string;
};

const PendientServicesTable = ({
  elements,
  activateOrDesactivateService,
}: {
  elements: IService[];
  activateOrDesactivateService: (id: string, isActive: boolean) => void;
}) => {
  const [userDeletedVisible, setUserDeletedVisible] = useState({
    isVisible: false,
    success: false,
  });
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useMantineTheme();

  return (
    <>
      <Table highlightOnHover className={classes.table}>
        <thead className={classes.tableHeader}>
          <tr>
            <th>
              <Text className={classes.headText}>{t("serviceName")}</Text>
            </th>
            <th>
              <Text className={classes.headText}>{t("requestedBy")}</Text>
            </th>
            <th>
              <Text className={classes.headText}>{t("applicationDate")}</Text>
            </th>
            <th style={{ textAlign: "center" }}>
              <Text className={classes.headText}>{t("actionsLabel")}</Text>
            </th>
          </tr>
        </thead>
        {elements.length > 0 ? (
          <tbody className={classes.bodyContainer}>
            {elements.map((element) => (
              <tr key={element.id}>
                <td>
                  <Text className={classes.tableText}>
                    {element.serviceName}
                  </Text>
                </td>
                <td>
                  <Text className={classes.tableText}>
                    {element?.requestedBy || "-"}
                  </Text>
                </td>
                <td>
                  <Text className={classes.tableText}>
                    {element.requestedAt}
                  </Text>
                </td>
                <td>
                  <Box className={classes.actionsContainer}>
                    <Button
                      variant="subtle"
                      className={classes.actionLink}
                      style={{
                        backgroundColor: theme.colors.dark[2],
                        color: theme.colors.dark[1],
                      }}
                      onClick={() =>
                        activateOrDesactivateService(element.id, true)
                      }
                    >
                      {t("approve")}
                    </Button>
                    <Button
                      variant="subtle"
                      className={classes.actionLink}
                      style={{
                        border: `1px solid red`,
                        color: "red",
                        marginLeft: "1rem",
                      }}
                      onClick={() =>
                        activateOrDesactivateService(element.id, false)
                      }
                    >
                      {t("decline")}
                    </Button>
                  </Box>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody className={classes.bodyContainer}>
            <tr>
              <td>
                <Text className={classes.emptyResultsMessage}>
                  {t("emptyResults")}
                </Text>
              </td>
            </tr>
          </tbody>
        )}
      </Table>
      {userDeletedVisible.isVisible && (
        <CustomModal
          isModalVisible={userDeletedVisible.isVisible}
          onCloseModal={() =>
            setUserDeletedVisible({
              isVisible: false,
              success: false,
            })
          }
          success={userDeletedVisible.success}
          title={"clientDeletedMessage"}
        />
      )}
    </>
  );
};

export default PendientServicesTable;

import {
  Box,
  Container,
  LoadingOverlay,
  Notification,
  Switch,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InfoCircle } from "tabler-icons-react";
import CustomHeaderBreadcrumbs from "../../../../Components/CustomHeaderBreadcrumbs";
import CustomModal from "../../../../Components/CustomModals/Modal/Modal";
import { useTranslation } from "react-i18next";
import {
  createUser,
  getClientsData,
  getUserById,
  updateUser,
  updateUserPassword,
  updateUserStatus,
} from "../../Controller/userController";
import { useStyles } from "./styles";
import RestorePasswordModal from "../../../../Components/CustomModals/RestorePasswordModal";
import { deleteUserById } from "../../../Clients/Controllers/usersController";
import Countdown from "../../../../Components/Countdown";
import CreateUserForm from "../../Components/CreateUserForm";
import OutletContainer from "../../../../Components/OutletContainer";
import { useViewportSize } from "@mantine/hooks";
import { ThemeMode } from "../../../../Enums/themeMode";

const ManageUsers = () => {
  const { t } = useTranslation();
  const { userId }: any = useParams();
  const theme = useMantineTheme();
  const [clientsList, setClientsList] = useState<any>([]);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { width } = useViewportSize();
  const iconSize = width > 700 ? "1.5rem" : "2rem";
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUserEnabled, setIsUserEnabled] = useState(false);
  const [userCompany, setUserCompany] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isRestorePasswordModalVisible, setIsRestorePasswordModalVisible] =
    useState(false);
  const [isNotificationErrorVisible, setNotificationErrorVisible] =
    useState(false);
  const [userDeletedVisible, setUserDeletedVisible] = useState({
    isVisible: false,
    success: false,
  });
  const [isModalVisible, setIsModalVisible] = useState({
    isVisible: false,
    success: false,
    message: "",
  });

  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      position: "",
      rol: "",
      company: "",
      toDeleteDate: "",
    },

    validate: {
      rol: (value) => (value ? null : "Campo requerido"),
      company: (value) => (value ? null : "Campo requerido"),
      email: (value) => {
        if (/^\S+@\S+$/.test(value)) {
          return null;
        } else if (value.length === 0) {
          return "Campo requerido";
        } else {
          return "Email invalido";
        }
      },
    },
  });

  const getClients = async () => {
    const { data, error } = await getClientsData();
    if (error !== null) {
      setNotificationErrorVisible(true);
    }
    setClientsList(data);
    setIsLoading(false);
  };

  const getUserData = async () => {
    const { data, error } = await getUserById(userId);
    if (error === null) {
      setIsUserEnabled(data.enabled);
      setUserCompany(data.companyId);
      form.setValues({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        phone: data?.phone,
        position: data?.position,
        rol: data?.rol,
        toDeleteDate: data?.toDeleteDate,
      });
    } else {
      setNotificationErrorVisible(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (userId) {
      getUserData();
    }
    getClients();
  }, []);

  useEffect(() => {
    if (clientsList?.length && (userCompany !== null || userCompany !== "")) {
      const company = clientsList.find(
        (element: any) => element.value === userCompany
      );
      form.setValues({
        company: company?.value,
      });
    }
  }, [clientsList, userCompany]);

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);
    if (userId) {
      const { error } = await updateUser(userId, values);
      setIsModalVisible({
        isVisible: true,
        success: error === null,
        message: error === null ? "userEdited" : "userEditedError",
      });
    } else {
      const { error } = await createUser(values);
      setIsModalVisible({
        isVisible: true,
        success: error === null || error === undefined,
        message:
          error === null || error === undefined
            ? "userCreated"
            : "userCreatedError",
      });
      if (error !== null) {
        form.reset();
      }
    }
    setIsSubmitting(false);
  };

  const updateStatus = async (status: boolean) => {
    setIsSubmitting(true);
    const { error } = await updateUserStatus(userId, status);
    setIsSubmitting(false);
    setIsModalVisible({
      isVisible: true,
      success: error === null,
      message: error === null ? "userEdited" : "userEditedError",
    });
  };

  const onChangePassword = async (option: string, password?: string) => {
    const { error } = await updateUserPassword(option, userId, password);
    setIsRestorePasswordModalVisible(false);
    setIsModalVisible({
      isVisible: true,
      success: error === null,
      message: error === null ? "userEdited" : "userEditedError",
    });
  };

  const onDeleteClient = async () => {
    setIsLoading(true);
    setIsDeleteModalVisible(false);
    const restoreOrDelete = form.values.toDeleteDate !== "" ? false : true;
    const { error } = await deleteUserById(userId, restoreOrDelete);
    if (error === null) {
      setIsLoading(true);
      if (userId) {
        getUserData();
      }
      getClients();
    }
  };

  const onCloseModal = () => {
    setIsModalVisible({
      ...isModalVisible,
      isVisible: false,
    });
    if (!userId) {
      navigate(-1);
    }
  };

  return (
    <>
      {!isLoading && form.values?.toDeleteDate && (
        <Countdown type={"usuario"} date={form.values?.toDeleteDate} />
      )}
      <CustomHeaderBreadcrumbs
        title={`${userId ? t("editUser") : t("createUser")}`}
        description={"usersAdm"}
      />
      <OutletContainer>
        <LoadingOverlay
          visible={isLoading}
          loaderProps={{ color: "green" }}
          overlayColor={
            theme.colorScheme === ThemeMode.DARK
              ? theme.colors.dark[0]
              : theme.colors.dark[1]
          }
        />
        <Container className={classes.mainContainer}>
          <Box className={classes.boxTitleAndSwitchContainer}>
            <Text className={classes.userInfoTitle}>{t("userInfoTitle")}</Text>
            {userId && (
              <Box className={classes.switchUserContainer}>
                {isSubmitting ? (
                  <Text className={classes.userStatusText}>
                    {t("processing")}
                  </Text>
                ) : (
                  <Text className={classes.userStatusText}>
                    {isUserEnabled ? t("userEnabled") : t("userDisabled")}
                  </Text>
                )}
                <Switch
                  checked={isUserEnabled}
                  onChange={() => {
                    updateStatus(!isUserEnabled);
                    setIsUserEnabled(!isUserEnabled);
                  }}
                  disabled={isSubmitting}
                  color={"green"}
                />
              </Box>
            )}
          </Box>
          <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <CreateUserForm
              isSubmitting={isSubmitting}
              form={form}
              userId={userId}
              clientsList={clientsList}
              setIsRestorePasswordModalVisible={
                setIsRestorePasswordModalVisible
              }
              setIsDeleteModalVisible={setIsDeleteModalVisible}
            />
          </form>
          {!userId && (
            <Box className={classes.passwordContainer}>
              <InfoCircle color={"grey"} size={iconSize} />
              <Text className={classes.passwordText}>
                {t("passwordGeneratedText")}
              </Text>
            </Box>
          )}
        </Container>
      </OutletContainer>
      {isModalVisible.isVisible && (
        <CustomModal
          isModalVisible={isModalVisible.isVisible}
          onCloseModal={() => onCloseModal()}
          title={isModalVisible.message}
          success={isModalVisible.success}
        />
      )}
      <RestorePasswordModal
        isVisible={isRestorePasswordModalVisible}
        onCloseModal={() => setIsRestorePasswordModalVisible(false)}
        username={`${form.values.firstName} ${form.values.lastName}`}
        onSubmit={onChangePassword}
      />
      {isDeleteModalVisible && (
        <CustomModal
          isModalVisible={isDeleteModalVisible}
          onCloseModal={() => setIsDeleteModalVisible(false)}
          description={
            form.values.toDeleteDate !== "" ? "" : "deleteClientDescription"
          }
          success={false}
          title={
            form.values.toDeleteDate !== ""
              ? "restoreUserTitle"
              : "deleteUserTitle"
          }
          buttons
          buttonCancelText={"cancelOption"}
          buttonCancelFunction={() => setIsDeleteModalVisible(false)}
          buttonConfirmText={
            form.values.toDeleteDate !== "" ? "restoreOption" : "deleteOption"
          }
          buttonConfirmFunction={onDeleteClient}
        />
      )}
      {userDeletedVisible.isVisible && (
        <CustomModal
          isModalVisible={userDeletedVisible.isVisible}
          onCloseModal={() =>
            setUserDeletedVisible({
              isVisible: false,
              success: false,
            })
          }
          success={userDeletedVisible.success}
          title={"userDeletedMessage"}
        />
      )}
      {isNotificationErrorVisible && (
        <Notification
          className={classes.notification}
          color="red"
          onClose={() => setNotificationErrorVisible(false)}
        >
          <Text>{t("generalError")}</Text>
        </Notification>
      )}
    </>
  );
};

export default ManageUsers;

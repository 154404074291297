import { Box, Button, Select, Text, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { rolesList } from "../../../Clients/Views/utils";
import { InfoCircle } from "tabler-icons-react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useViewportSize } from "@mantine/hooks";
import { ThemeMode } from "../../../../Enums/themeMode";

type ICreateUserForm = {
  isSubmitting: boolean;
  form: any;
  userId: string | undefined;
  clientsList: any[];
  setIsRestorePasswordModalVisible: (value: boolean) => void;
  setIsDeleteModalVisible: (value: boolean) => void;
};

const CreateUserForm = ({
  isSubmitting,
  form,
  userId,
  clientsList,
  setIsRestorePasswordModalVisible,
  setIsDeleteModalVisible,
}: ICreateUserForm) => {
  const { t } = useTranslation();
  const { width } = useViewportSize();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const inputWidth = width > 700 ? 530 : 290;

  return (
    <>
      <Box className={classes.boxItemsContainer}>
        <TextInput
          label={t("nameLabel")}
          disabled={isSubmitting}
          {...form.getInputProps("firstName")}
          size="sm"
          inputWrapperOrder={["label", "input", "description", "error"]}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isSubmitting
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isSubmitting
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
        <TextInput
          label={t("lastnameLabel")}
          disabled={isSubmitting}
          {...form.getInputProps("lastName")}
          size="sm"
          inputWrapperOrder={["label", "input", "description", "error"]}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isSubmitting
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isSubmitting
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
      </Box>
      <Box className={classes.boxItemsContainer}>
        <TextInput
          label={t("phoneLabel")}
          disabled={isSubmitting}
          {...form.getInputProps("phone")}
          size="sm"
          inputWrapperOrder={["label", "input", "description", "error"]}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isSubmitting
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isSubmitting
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
        <TextInput
          label={t("emailLabel")}
          disabled={isSubmitting}
          {...form.getInputProps("email")}
          size="sm"
          inputWrapperOrder={["label", "input", "description", "error"]}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isSubmitting
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isSubmitting
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
      </Box>
      <Box className={classes.boxItemsContainer}>
        <Box
          style={{
            width: inputWidth,
          }}
        >
          <Select
            placeholder={t("selectRol")}
            disabled={isSubmitting || userId ? true : false}
            data={rolesList}
            label={"Rol"}
            errorMessage={form?.errors?.rol || false}
            required
            value={form.values.rol}
            {...form.getInputProps("rol")}
            styles={(theme) => ({
              label: {
                color:
                  theme.colorScheme === ThemeMode.DARK
                    ? theme.colors.dark[1]
                    : theme.colors.light[1],
              },
              input: {
                color:
                  theme.colorScheme === ThemeMode.DARK
                    ? theme.colors.dark[1]
                    : "#000",
                border: (isSubmitting || userId ? true : false)
                  ? `0.5px solid ${theme.colors.dark[4]}`
                  : theme.colorScheme === ThemeMode.DARK
                  ? `1px solid ${theme.colors.dark[3]}`
                  : "0.5px solid",
                backgroundColor:
                  theme.colorScheme === ThemeMode.DARK
                    ? "transparent"
                    : theme.colors.light[0],
                "&:focus-within": {
                  borderColor: theme.colors.green[7],
                },
              },
            })}
          />
        </Box>
      </Box>
      <Box className={classes.boxItemsContainer}>
        <TextInput
          label={t("position")}
          disabled={isSubmitting}
          placeholder={t("selectPosition") || ""}
          {...form.getInputProps("position")}
          size="sm"
          inputWrapperOrder={["label", "input", "description", "error"]}
          styles={(theme) => ({
            label: {
              color:
                theme.colorScheme === ThemeMode.DARK
                  ? theme.colors.dark[1]
                  : theme.colors.light[1],
            },
            input: {
              width: inputWidth,
              border: isSubmitting
                ? `0.5px solid ${theme.colors.dark[4]}`
                : theme.colorScheme === ThemeMode.DARK
                ? `1px solid ${theme.colors.dark[3]}`
                : "0.5px solid",
              backgroundColor:
                theme.colorScheme === ThemeMode.DARK
                  ? "transparent"
                  : theme.colors.light[0],
              marginRight: "2rem",
              "&:focus-within": {
                borderColor: theme.colors.green[7],
              },
              color: isSubmitting
                ? "#000!important"
                : theme.colorScheme === ThemeMode.DARK
                ? theme.colors.dark[1]
                : theme.colors.light[1],
            },
          })}
        />
        <Box
          style={{
            width: inputWidth,
            marginRight: '1.7rem'
          }}
        >
          <Select
            placeholder={t("selectCompany")}
            disabled={isSubmitting}
            data={clientsList}
            label={t("companyLabel")}
            required
            errorMessage={form?.errors?.company || false}
            {...form.getInputProps("company")}
            value={form.values.company}
            styles={(theme) => ({
              label: {
                color:
                  theme.colorScheme === ThemeMode.DARK
                    ? theme.colors.dark[1]
                    : theme.colors.light[1],
              },
              input: {
                width: inputWidth,
                color:
                  theme.colorScheme === ThemeMode.DARK
                    ? theme.colors.dark[1]
                    : "#000",
                border: (isSubmitting || form.values.company ? true : false)
                  ? `0.5px solid ${theme.colors.dark[4]}`
                  : theme.colorScheme === ThemeMode.DARK
                  ? `1px solid ${theme.colors.dark[3]}`
                  : "0.5px solid",
                backgroundColor:
                  theme.colorScheme === ThemeMode.DARK
                    ? "transparent"
                    : theme.colors.light[0],
                "&:focus-within": {
                  borderColor: theme.colors.green[7],
                },
              },
            })}
          />
        </Box>
      </Box>
      {userId && (
        <>
          <Button
            variant="outline"
            className={classes.resetPassword}
            disabled={isSubmitting}
            onClick={() => setIsRestorePasswordModalVisible(true)}
          >
            <Text>{t("resetPassword")}</Text>
          </Button>
          <Box className={classes.footerButtons}>
            <Box className={classes.buttonsContainer}>
              <Button
                className={classes.buttonAdd}
                type={"submit"}
                disabled={isSubmitting}
              >
                <Text>{isSubmitting ? t("loading") : t("save")}</Text>
              </Button>
              <Button
                variant="outline"
                className={classes.buttonCancel}
                onClick={() => navigate(-1)}
                disabled={isSubmitting}
              >
                <Text>{t("cancelOption")}</Text>
              </Button>
            </Box>
            <Box className={classes.deleteRowContainer}>
              <InfoCircle size={"3rem"} color="grey" />
              <Text className={classes.deleteWarningText}>
                <strong>{t("warning")}:</strong>{" "}
                {form.values.toDeleteDate !== ""
                  ? `${t("warningRestoreUserDescription")} ${format(
                      new Date(form.values.toDeleteDate),
                      "dd/MM/yyyy"
                    )}`
                  : t("warningDescription")}
              </Text>
              <Button
                variant={form.values.toDeleteDate !== "" ? "subtle" : "filled"}
                className={
                  form.values.toDeleteDate !== ""
                    ? classes.buttonRestore
                    : classes.buttonDelete
                }
                disabled={isSubmitting}
                onClick={() => setIsDeleteModalVisible(true)}
              >
                <Text>
                  {form.values.toDeleteDate !== ""
                    ? t("restoreOption")
                    : t("delete")}
                </Text>
              </Button>
            </Box>
          </Box>
        </>
      )}
      {!userId && (
        <Box className={classes.buttonsContainer}>
          <Button
            disabled={isSubmitting}
            className={classes.buttonAdd}
            type={"submit"}
          >
            <Text>{isSubmitting ? t("loading") : t("add")}</Text>
          </Button>
          <Button
            variant="outline"
            disabled={isSubmitting}
            className={classes.buttonCancel}
            type={"reset"}
            onClick={() => navigate(-1)}
          >
            <Text>{t("cancelOption")}</Text>
          </Button>
        </Box>
      )}
    </>
  );
};

export default CreateUserForm;
